import { Signal } from '../signals/Signal';
import axios, { CancelTokenSource } from 'axios';
import { AbstractTemplate } from '../../../../src/templates/AbstractTemplate';
import { Globals } from '../../../../src/utils/Globals';

export class TemplateManager {
	public signalHashChange: Signal = new Signal();

	private _passedVariables;

	private _templateRegister: { templateName: string; template: typeof AbstractTemplate }[] = [];
	private _activeTemplates: {
		path: string;
		data: object;
		template: AbstractTemplate;
	}[] = [];

	private _blocked = false;

	private _newPath = '';
	private _defaultPath = '';
	private _curPath = '';

	private _initial = true;

	private _initialData;

	private _queue = [];

	private _loadProgresses = [];

	private _prevFullUrl;
	private _newFullUrl;

	private _loadQue = [];

	private _loading = false;

	public asyncLoad = true;
	public trackGoogleAnalytics = true;
	public useHistoryApi = true;
	public defaultUrl = document.location.protocol + '//' + window.location.host;
	public defaultSubfolder = '';
	public prevPath = '';

	private http = axios.create();
	private cancelRequest: CancelTokenSource = null;

	public init(data, defaultPath: string) {
		this._initialData = data;

		if (defaultPath) {
			this._defaultPath = defaultPath;
		}

		if (window.history === null) {
			this.useHistoryApi = false;
		}

		if (this.useHistoryApi === true) {
			history.scrollRestoration = 'manual';
			window.addEventListener('popstate', this.onHashChange);
		} else {
			window.addEventListener('hashchange', this.onHashChange);
		}

		this._curPath = 'initialLoad';
		this._newPath = this._curPath;
		this._prevFullUrl = this._curPath;

		// force initial hash change
		this.onHashChange();
	}

	isDifferentLanguage(oldPath, url) {
		var hasLanguage = false;
		var newUrlHasLanguage = false;
		Globals.LANGUAGES.forEach(lang => {
			if (oldPath.indexOf('/' + lang) >= 0) {
				hasLanguage = true;
			}
		});
		Globals.LANGUAGES.forEach(lang => {
			if (url.indexOf('/' + lang) >= 0) {
				newUrlHasLanguage = true;
			}
		});
		console.log(newUrlHasLanguage, hasLanguage, oldPath,"---", url);
		if (newUrlHasLanguage || hasLanguage) {
			if (oldPath.substr(0, 3) !== url.substr(0, 3)) {
				return true;
			}
		}
		return false;
	}

	onHashChange = (e = null) => {
		// Parse the current url
		var url = this.getPath();
		this._newFullUrl = url;
                var loadWasAborted = false;

		url = url.split('?')[0];

		if (this.useHistoryApi) {
			url = url.split('#')[0];
		}

		this._newPath = this.extractPath(url);

		// if the new path is empty, set it to the default path
		if (this._newPath === '') {
			this._newPath = this._defaultPath;
		}

		let historyNavigationOccured = e !== null;

		// // If the path has changed then load the corresponding data
		if (this._curPath !== this._newPath) {
			console.log(this._newPath, this._curPath);
			if (this._curPath !== 'initialLoad' && this.isDifferentLanguage('/' + this._curPath, '/' + this._newPath)) {
				console.log('/' + this._newPath);
				if (this._newPath === 'home') {
					this._newPath = '';
				}
				window.location.href = '/' + this._newPath;
				return;
			}
			this.prevPath = this._curPath;
			this._curPath = this._newPath;

			// If there is an active template, run template out on it
			if (this._activeTemplates.length > 0) {
				// && !_blocked)
				this._blocked = true;

				var oldTemplateObj;

				var l = this._activeTemplates.length;

				for (var i = 0; i < l; i++) {
					oldTemplateObj = this._activeTemplates.pop();
					oldTemplateObj.template.templateOut(this._newPath);
				}
			}

			// Abort any load progress
			if (this._loadProgresses.length > 0) {
				var process = this._loadProgresses.pop();
				this.cancelRequest.cancel();
				loadWasAborted = true;
			}

			if (this._queue.length > 0) {
				this._queue = [];
			}

			// The data for the initial page is pre rendered by the server
			if (this._initial) {
                        	this.dataLoaded(this._initialData, this._newPath);
			} else {
				// Reload the Covid 19 care landing page.
				if (this._newPath === 'covid-19-care' && historyNavigationOccured) {
					window.location.reload();
				}
				else {
					if (this.asyncLoad) {
						this.loadData(this._newPath, historyNavigationOccured);
					} else {
						if (loadWasAborted) {
							this.loadData(this._newPath, historyNavigationOccured);
						} else {
							this._loadQue.push(this._newPath);
						}
					}
			     	}
			}
		}

		this._prevFullUrl = this._newFullUrl;

		this.signalHashChange.dispatch({ fullUrl: this._newFullUrl });
	};

	loadData(path, historyNavigationOccured = false) {
		var finalPath;

		if (path === 'home') {
			path = '';
		}
		finalPath = this.defaultUrl + '/' + path; // + '?ajax=1';

		this.cancelRequest = axios.CancelToken.source();

		var request = this.http
			.get(finalPath, {
				cancelToken: this.cancelRequest.token
			})
			.then(result => this.dataLoaded(result.data, finalPath, historyNavigationOccured))
			.catch(this.onLoadError);

		this._loadProgresses.push(request);
	}

	onLoadError = error => {
		console.log('error: ' + error);
		window.location.reload();
	};

	dataLoaded = (data, path: string, historyNavigationOccured = false) => {
		if (!this.asyncLoad) {
			this._loading = false;
		}

		if (this._loadProgresses.length > 0) {
			var process = this._loadProgresses.pop();
			process = null;
		}

		// Parse data
		if (this._initial) {
			data = data.querySelector('.template');
		} else {
			var html = document.createElement('div');
			html.innerHTML = data;

			data = html;
			data = data.querySelector('.template');
		}

		// console.log(this._initial, data, path, this._blocked);

		// If there is no active templates, create a new and run templateIn
		if (this._initial) {
			var template = this.initTemplate(data, path);
			//	if (template !== undefined) {
			template.templateIn(historyNavigationOccured);
			//	}

			this._initial = false;
		} else if (this._blocked) {
			this._queue.push({
				path: path,
				data: data
			});
		} else {
			var template = this.initTemplate(data, path);
			console.log(template);
			template.templateIn(historyNavigationOccured);

			this._blocked = true;
		}
	};

	initTemplate(data: HTMLElement, path: string) {
		this.updateTitle(data);

		let template = this.getTemplate(data, data.getAttribute('data-template'));
		//if (template !== undefined) {
		template.path = path;
		//}

		// Google Analytics
		if (this.trackGoogleAnalytics === true && !this._initial) {
			// Google Analytics tracking
			if (typeof (window as any).gtag !== 'undefined') {
				if (path === '') {
					path = '/';
				}

				if (path.substring(0, 1) !== '/') {
					path = '/' + path;
				}
        (window as any).gtag('event', 'page_view', { page_path: path });
			}
			// Twitter tracking.
			if (typeof (window as any).twq !== 'undefined') {
				if (path === '') {
					path = '/';
				}

				if (path.substring(0, 1) !== '/') {
					path = '/' + path;
				}
        (window as any).twq('track','PageView');
			}
			// Facebook Pixel tracking.
			/*if (typeof (window as any).fbq !== 'undefined') {
				if (path === '') {
					path = '/';
				}

				if (path.substring(0, 1) !== '/') {
					path = '/' + path;
				}
        (window as any).fbq('track', 'PageView');
			}*/
		}

		this._activeTemplates.push({
			path: path,
			data: data,
			template: template
		});

		return template;
	}

	updateTitle(data) {
		var title = data.getAttribute('data-title');

		if (!title) {
			title = '';
		}

		document.head.querySelector('title').textContent = title + ' | Sesame Workshop India';
	}

	public getTemplate = (data: HTMLElement, templateName: string, isOverlay?) => {
		let l = this._templateRegister.length;
		let template: AbstractTemplate;
		let found = false;

		for (let i = 0; i < l; i++) {
			if (this._templateRegister[i].templateName === templateName) {
				template = new this._templateRegister[i].template(data, this);
				found = true;
				break;
			}
		}

		//if (!found) {
		//console.error('Template with name: ' + templateName + ' not found');
		//	}

		return template;
	};

	public getCurrentActiveTemplate = () => {
		return this._activeTemplates[0];
	};

	/**
	 * Format the url
	 * Ex. if you pass "http://www.hellomonday.com/#/this/is/path"
	 * you will get "this/is/path"
	 *
	 * @param str {string} - the path which should be converted
	 * @private
	 */
	public extractPath = str => {
		var arr1 = str.split('#');
		var arr2 = arr1[arr1.length - 1].split('/');
		var arr3 = [];
		var l = arr2.length;
		var currPart;

		for (var i = 0; i < l; i += 1) {
			currPart = arr2[i];
			if (currPart !== null && currPart !== '') {
				arr3.push(currPart);
			}
		}
		var newPath = arr3.join('/');
		return newPath;
	};

	public getPath() {
		var url = '';
		if (this.useHistoryApi === true) {
			var fullURL = window.location.href;
			url = fullURL.substring(this.defaultUrl.length + this.defaultSubfolder.length, fullURL.length);
		} else {
			url = window.location.hash;
		}

		return url;
	}

	public path = (newPath: string, subPath?: string) => {
		// if (_loading) {
		//     return;
		// }

		if (this.useHistoryApi === true) {
			newPath = '/' + newPath;
			history.pushState(newPath, newPath, newPath);
		} else {
			window.location.hash = '/' + newPath;
		}

		if (this.useHistoryApi) {
			this.onHashChange(subPath);
		}
	};

	public isInitial() {
		return this._initial;
	}

	public addTemplate(templateName: string, template: typeof AbstractTemplate) {
		this._templateRegister.push({
			templateName: templateName,
			template: template
		});
	}

	/**
	 * Function for allowing next template to be added
	 * You should call this function after templateIn() and templateOut()
	 */
	public nextTemplate = (passedVars?) => {
		this._passedVariables = passedVars || {};

		if (this.asyncLoad) {
			if (this._queue.length > 0) {
				var que = this._queue.pop();
				var template = this.initTemplate(que.data, que.path);

				template.templateIn();

				this._blocked = true;
			} else {
				this._blocked = false;
			}
		} else if (this._loadQue.length > 0) {
			this.loadData(this._loadQue.shift());
			this._blocked = false;
			this._loading = true;
		}
	};

	public resize = () => {
		var i = 0;
		var l = this._activeTemplates.length;

		for (i; i < l; i++) {
			//if (this._activeTemplates[i].template !== undefined) {
			this._activeTemplates[i].template.resize();
			//}
		}
	};

	public getCurrentPath() {
		return this._curPath;
	}
}
