import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import * as Qs from 'qs';

export class BigDonateModule extends AbstractModule {
	private monthlyForm: HTMLFormElement;
	private monthlyPrices: NodeListOf<HTMLElement>;
	private activeIndex = 0;
	private radioOptions: NodeListOf<HTMLInputElement>;
	private inputOption: HTMLInputElement;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		element.querySelectorAll('form').forEach(form => {
			form.addEventListener('submit', this.submitHandler);
		});

		this.monthlyPrices = element.querySelectorAll('form.give-monthly .monthly-price');

		this.monthlyForm = element.querySelector('form.give-monthly');
		this.monthlyForm.addEventListener('change', this.checkAmount);
		this.inputOption = element.querySelector('form.give-monthly .text-input input');
		this.radioOptions = element.querySelectorAll('form.give-monthly .donate-choices input');
		this.inputOption.addEventListener('change', () => {
			console.log('change');
			this.radioOptions.forEach(option => {
				option.checked = false;
			});
		});

		this.radioOptions.forEach(option => {
			option.addEventListener('change', () => {
				console.log('change raido');
				// this.inputOption.value = '';
			});
		});
	}

	private submitHandler = event => {
		if (event) {
			event.preventDefault();
		}

		let formData = new FormData(event.target);
		let data = {};

		for (let pair of formData.entries()) {
			data[pair[0]] = pair[1];
		}
		let data2 = {};
		let i = 0;
		for (var pair of formData.entries()) {
			data2[i] = pair[1];
			i++;
		}

		//let formQueryString = Qs.stringify(data);
		let formQueryString = 'donate-amount=' + String(data2[0]);
		//Build form url without "/" prefix:
		let formUrl = event.target.getAttribute('action').substring(1) + '?' + formQueryString;
		window.Main.templateManager.path(formUrl);

		return false;
	};

	private checkAmount = () => {
		let formData = new FormData(this.monthlyForm);
		let data = {};

		for (let pair of formData.entries()) {
			data[pair[0]] = pair[1];
		}
		let data2 = {};
		let i = 0;
		for (var pair of formData.entries()) {
			data2[i] = pair[1];
			i++;
		}

		//let donateAmount2 = parseInt(data['donate-amount']);
		let donateAmount = data2[0];
		console.log(donateAmount);

		let activeIndex = 0;
		for (let i = this.monthlyPrices.length - 1; i >= 0; i--) {
			const price = this.monthlyPrices[i];
			if (donateAmount >= parseInt(price.dataset.minimumAmount)) {
				activeIndex = i;
				break;
			}
		}

		if (this.activeIndex !== activeIndex) {
			this.monthlyPrices[this.activeIndex].classList.remove('active-price');
			this.activeIndex = activeIndex;
			this.monthlyPrices[this.activeIndex].classList.add('active-price');
		}
	};
}
