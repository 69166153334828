import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { NumberStatBox } from './stats/NumberStatBox';

export class NumberStatsModule extends AbstractModule {
	private _boxes: NumberStatBox[];

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		const boxes = element.querySelectorAll('.stat');

		boxes.forEach(box => {
			this._boxes.push(new NumberStatBox(box as HTMLElement));
		});
	}
}
