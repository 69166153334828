import { Signal } from '../../../lib/com/hellomonday/signals/Signal';
import { Button } from '../../buttons/Button';
import { TweenMax } from 'gsap/TweenMax';

export class LearnMorelItem {
	public onActivate = new Signal();
	public onScrollToItem = new Signal();

	public element: HTMLElement;
	private _button: Button;
	private _id: number;
	private _isActive: boolean = false;

	public snapIndex: number;

	constructor(element: HTMLElement, id: number) {
		this.element = element;
		this._id = id;

		this._button = new Button(element);
		this._button.onClick.add(this.fireScrollToItem, this);
	}

	public fireScrollToItem() {
		this.onScrollToItem.dispatch(this);
	}

	public getId(): number {
		return this._id;
	}

	public scaleItem(carouselX: number) {
		/*		let distance: number = 0;

		distance += parseInt(carouselX.toString());
		distance += this.element.offsetLeft;

		if (carouselX >= -this.element.offsetLeft) {
			this.element.style.transformOrigin = 'left center';
		} else {
			this.element.style.transformOrigin = 'right center';
		}

		distance = Math.abs(distance);

		let floatDistance = 1 - distance / this.element.offsetWidth;

		if (floatDistance < 0) {
			floatDistance = 0;
		}

		floatDistance = 0.8 + floatDistance * 0.2;

		TweenMax.set(this.element, { scale: floatDistance });*/
	}

	public activate(fireEvent: boolean = true) {
		if (this._isActive) return;

		this.element.classList.add('active');

		this._isActive = true;

		if (fireEvent) {
			this.onActivate.dispatch(this);
		}
	}

	public deactivate() {
		this.element.classList.remove('active');

		this._isActive = false;
	}

	public isActive(): boolean {
		return this._isActive;
	}

	public kill() {
		this._button.onClick.remove(this.fireScrollToItem, this);
	}
}
