import { AbstractComponent } from '../../../lib/com/hellomonday/templateManager/AbstractComponent';
import { CarouselDotButton } from '../../buttons/CarouselDotButton';
import { CarouselItem } from './CarouselItem';

export class CarouselDots extends AbstractComponent {
	private _container: HTMLElement;
	private _dots: CarouselDotButton[] = [];

	constructor(container: HTMLElement) {
		super();

		this._container = container;
	}

	public createDot(carouselItem: CarouselItem) {
		let element = document.createElement('div');
		element.classList.add('dot');

		this._container.appendChild(element);

		this._dots.push(new CarouselDotButton(element, carouselItem));
		carouselItem.onActivate.add(this.updateActive, this);

		if (carouselItem.isActive()) {
			element.classList.add('active');
		}
	}

	private updateActive(item: CarouselItem) {
		this._dots.forEach(dot => {
			if (dot.getCarouselItem().getId() == item.getId()) {
				dot.setActive();
			} else {
				dot.setInactive();
			}
		});
	}

	public kill = () => {
		this._dots.forEach(dot => {
			dot.removeClickListener();
			dot.getCarouselItem().onActivate.remove(this.updateActive, this);
		});
	};
}
