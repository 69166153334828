import { Globals } from '../../utils/Globals';

export class TimelineElement {
	protected element: HTMLElement;

	constructor(element: HTMLElement) {
		this.element = element;
	}

	public getXPos(): number {
		// console.log(this.element);
		if (Globals.RTL) {
			return this.element.offsetLeft + this.element.offsetWidth;
		}
		return this.element.offsetLeft;
	}

	public getElement(): HTMLElement {
		return this.element;
	}
}
