import { Menu } from '../menu/Menu';

//Keep typescript from complaining about touch test.
declare class DocumentTouch {}
export class Globals {
	public static DEBUG: boolean = true;

	public static SITE_WRAPPER: HTMLDivElement = document.getElementById('SiteWrapper') as HTMLDivElement;
	public static TEMPLATE_LAYER = document.getElementById('TemplateLayer') as HTMLDivElement;
	public static IS_TOUCH_DEVICE: boolean = 'ontouchstart' in window || ((window as any).DocumentTouch && document instanceof DocumentTouch); // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
	public static IS_IE: boolean = false;

	public static WHITELISTED_DOMAINS: string[] = ['127.0.0.1', 'localhost', '192.168', window.location.hostname];
	public static LANGUAGES: string[] = ['es', 'de', 'fr', 'ar', 'pt-pt', 'ja', 'zh-hans'];

	public static MENU: Menu;
	public static RTL = document.documentElement.getAttribute('lang') === 'ar';
}
