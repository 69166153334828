import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';

export abstract class IntersectionObserverModule extends AbstractModule {
	private _intersectionObserver: IntersectionObserver;

	public constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._intersectionObserver = new IntersectionObserver(this.check, this.getOptions());
	}

	protected abstract elementIntersectFound(element: Element);
	protected abstract getOptions(): IntersectionObserverInit;

	protected observe(element: Element) {
		this._intersectionObserver.observe(element);
	}

	private check = (observerEntries: IntersectionObserverEntry[]) => {
		observerEntries.forEach(observerEntry => {
			if (observerEntry.isIntersecting) {
				this.elementIntersectFound(observerEntry.target);
				this._intersectionObserver.unobserve(observerEntry.target);
			}
		});
	};

	public kill() {
		this._intersectionObserver.disconnect();
		this._intersectionObserver = null;

		super.kill();
	}
}
