import { Carousel } from './carousel/Carousel';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { CarouselDots } from './carousel/CarouselDots';
import { NextPrevButtons } from './timeline/NextPrevButtons';
import { LearnMorelItem } from './carousel/LearnMorelItem';

export class LearnMoreBreakerSliderModule extends Carousel {
	private _carouselItems: LearnMorelItem[];
	private _dots: CarouselDots;
	private _nextPrevButtons: NextPrevButtons;

	private _currentCarouselItem: LearnMorelItem;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element.querySelector('.dragged-area'), element.querySelector('.carousel-container'), element, template);
	}

	protected prepare() {
		this._dots = new CarouselDots(this.element.querySelector('.dots-container'));
		this.prepareImages();
		// this._nextPrevButtons = new NextPrevButtons(this.element, this);

		super.prepare();

		this.onSnapChanged.add(this.snapChanged, this);
	}

	protected getWidth(): number {
		let firstItem = this._carouselItems[0];

		return firstItem.element.clientWidth * this._carouselItems.length;
	}

	private prepareImages() {
		const carouselItems: NodeListOf<HTMLElement> = this.element.querySelectorAll('.carousel-item');
		const itemCount: number = carouselItems.length;

		this._carouselItems = [];

		for (let i = 0; i < itemCount; i++) {
			const item = new LearnMorelItem(carouselItems[i], i);

			this._carouselItems.push(item);
			this._dots.createDot(item as any);

			item.onActivate.add(this.itemActivated, this);
			item.onScrollToItem.add(this.scrollToItem, this);
		}

		this.updateSnapPoints();
		this._carouselItems[0].fireScrollToItem();
	}

	private scrollToItem(item: LearnMorelItem) {
		this.tweenToSnapIndex(item.snapIndex);
	}

	private snapChanged(newIndex: number) {
		this._carouselItems.forEach(element => {
			if (element.snapIndex == newIndex) {
				element.activate();
			}
		});
	}

	private itemActivated(item: LearnMorelItem) {
		if (this._currentCarouselItem != null) this._currentCarouselItem.deactivate();
		item.activate();

		this._currentCarouselItem = item;
	}

	protected getSnapPoints(): number[] {
		let snapPoints = [];
		let firstItemWidth = this._carouselItems[0].element.clientWidth;
		for (let i = 0; i < this._carouselItems.length; i++) {
			this._carouselItems[i].snapIndex = i;
			snapPoints.push(-firstItemWidth * i);
		}

		return snapPoints;
	}

	public kill() {
		this._carouselItems.forEach(item => {
			item.onActivate.remove(this.itemActivated, this);
			item.onScrollToItem.remove(this.scrollToItem, this);
			item.kill();
		});

		if (this._nextPrevButtons) {
			this._nextPrevButtons.kill();
		}

		super.kill();
	}
}
