import { Signal } from '../../../lib/com/hellomonday/signals/Signal';
import { Button } from '../../buttons/Button';
import { Carousel } from '../carousel/Carousel';

export class NextPrevButtons {
	private _carousel: Carousel;

	public prevButton: Button;
	public nextButton: Button;

	constructor(element: HTMLElement, carousel: Carousel) {
		this._carousel = carousel;

		this.prevButton = new Button(element.querySelector('.previous-btn'));
		this.nextButton = new Button(element.querySelector('.next-btn'));

		this.prevButton.onClick.add(this.prevClick, this);
		this.nextButton.onClick.add(this.nextClick, this);

		this._carousel.onSnapChanged.add(this.updateButtonStates, this);

		this.updateButtonStates(0);
	}

	public updateButtonStates(newIndex: number) {
		if (newIndex <= 0) {
			this.prevButton.element.classList.add('disabled');
			this.nextButton.element.classList.remove('disabled');
		} else if (newIndex >= this._carousel.getMaxSnapPointIndex()) {
			this.prevButton.element.classList.remove('disabled');
			this.nextButton.element.classList.add('disabled');
		} else {
			this.prevButton.element.classList.remove('disabled');
			this.nextButton.element.classList.remove('disabled');
		}
	}

	private prevClick = () => {
		this._carousel.previous();
	};

	private nextClick = () => {
		this._carousel.next();
	};

	public kill() {
		this.prevButton.onClick.remove(this.prevClick, this);
		this.nextButton.onClick.remove(this.nextClick, this);
		this._carousel.onSnapChanged.remove(this.updateButtonStates, this);
	}
}
