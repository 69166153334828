import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import Axios from 'axios';
import { TweenMax } from 'gsap/TweenMax';
import isEmail from 'validator/lib/isEmail';
import * as Qs from 'qs';

export class NewsletterFullSignupFormModule extends AbstractModule {
	private formElement: HTMLFormElement;
	private emailInput: HTMLInputElement;
	private submitButton: HTMLInputElement;

	private submittingForm = false;

	private defaultSubmitValue = '';
	private firstNameInput: HTMLInputElement;
	private lastNameInput: HTMLInputElement;
	private zipInput: HTMLInputElement;

	private errorMessageElement: HTMLDivElement;
	private trackingError: boolean = false;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this.formElement = element.querySelector('form');
		this.submitButton = element.querySelector('input[type="submit"]');
		this.emailInput = element.querySelector('input[type="email"]');
		this.firstNameInput = element.querySelector('input[name="firstname"]');
		this.lastNameInput = element.querySelector('input[name="lastname"]');
		this.zipInput = element.querySelector('input[name="zipcode"]');
		this.errorMessageElement = element.querySelector('.NewsletterFullSignupFormModule__Result');
		this.defaultSubmitValue = this.submitButton.value;
		// this.formElement.addEventListener('submit', this.submitHandler);
		this.formElement.addEventListener('invalid', this.invalidHandler, true);

		this.checkQueryPathErrors();
	}

	//TODO: @lasse, finish the ui for success / error up when Henrik has design.
	private submitHandler = (e: Event) => {
		if (e) {
			e.preventDefault();
		}

		if (!this.submittingForm) {
			let email = this.emailInput.value;

			if (isEmail(email)) {
				this.emailInput.classList.remove('error');
				this.submittingForm = true;
				this.submitButton.disabled = true;

				let formData = new FormData();
				formData.append(this.firstNameInput.name, this.firstNameInput.value);
				formData.append(this.lastNameInput.name, this.lastNameInput.value);
				formData.append('email', email);
				formData.append(this.zipInput.name, this.zipInput.value);

				this.submitButton.value = 'Submitting...';
				TweenMax.to(this.submitButton, 0.5, { opacity: 0.7, yoyo: true, repeat: -1 });

				Axios.post(this.formElement.action, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then(this.submitResultHandler)
					.catch(this.submitErrorHandler);
			} else {
				this.emailInput.classList.add('error');
			}
		}

		return false;
	};

	private submitResultHandler = response => {
		console.log(response);
		if (!this.killed) {
			TweenMax.to(this.submitButton, 0.5, { opacity: 1 });
			this.submitButton.disabled = false;
			this.submittingForm = false;
			this.submitButton.value = this.defaultSubmitValue;
			console.log(response);
			this.errorMessageElement.textContent = response.toString();
		}
	};

	private submitErrorHandler = response => {
		console.log(response);
		if (!this.killed) {
			TweenMax.to(this.submitButton, 0.5, { opacity: 1 });
			this.submitButton.disabled = false;
			this.submittingForm = false;
			this.submitButton.value = this.defaultSubmitValue;
			this.errorMessageElement.textContent = response.toString();
		}
	};

	public kill() {
		super.kill();
		this.formElement.removeEventListener('submit', this.submitHandler);
	}

	private checkQueryPathErrors() {
		if (window.location.search) {
			let params = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
			if (params['errorMessage']) {
				this.errorMessageElement.innerHTML = params['errorMessage'].split('~~~').join('<br>');
				this.trackError();
			}
			if (params['email']) {
				this.emailInput.value = params['email'];
			}
		}
	}

	private trackError() {
		(window as any).dataLayer.push({
			event: 'User Action',
			eventCategory: 'User Action',
			eventAction: 'Sign Up',
			eventLabel: 'Newsletter_Sign Up Unsuccessful'
		});
		this.trackingError = false;
	}

	private invalidHandler = () => {
		if (this.trackingError === false) {
			this.trackingError = true;
			setTimeout(() => {
				this.trackError();
			}, 1);
		}
	};
}
