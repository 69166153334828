interface CollectParams {
	attr?: string;
	to?: Object;
	keepAttribute?: boolean;
}

interface FaconDocumentFragment extends DocumentFragment {
	collect({  }: CollectParams): Object;
}

export default function facon(templateString, ...args) {
	// let result = ``;
	// for (let i = 0; i < args.length; i++) result += strings[i] + args[i];
	// result += strings[strings.length - 1];

	const template = document.createElement('template');
	template.innerHTML = templateString;

	const content = template.content as FaconDocumentFragment;

	content.collect = ({ attr = 'ref', keepAttribute = false, to = {} } = {}) => {
		const refElements = content.querySelectorAll(`[${attr}]`);
		return [...refElements].reduce((acc, element) => {
			const propName = element.getAttribute(attr).trim();
			!keepAttribute && element.removeAttribute(attr);
			acc[propName] = acc[propName] ? (Array.isArray(acc[propName]) ? [...acc[propName], element] : [acc[propName], element]) : element;
			return acc;
		}, to);
	};

	return content;
}
