import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { StoryTab } from './storyTabs/StoryTab';
import { TweenMax } from 'gsap/TweenMax';
import { Draggable } from '../../lib/com/greensock/gsap-bonus/Draggable.js';
import { WindowManager } from '../utils/WindowManager';

export class TabbedStoriesModule extends AbstractModule {
	private _tabs: StoryTab[] = [];
	private _textContainer: HTMLElement;
	private _tabsContainer: HTMLElement;
	private _activeTab: StoryTab;
	private _draggable: Draggable;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._tabsContainer = element.querySelector('.tabs');
		this._textContainer = element.querySelector('.stories');

		let tabs = element.querySelectorAll('.tabs>.section');
		let tabContent = element.querySelectorAll('.stories>.story-container');

		for (let i = 0; i < tabs.length; i++) {
			let storyTab = new StoryTab(tabs[i] as HTMLElement, tabContent[i] as HTMLElement, i);

			storyTab.onClick.add(this.activateTab, this);
			this._tabs.push(storyTab);
		}

		this._draggable = Draggable.create(this._tabsContainer, {
			type: 'x',
			throwProps: true,
			overshootTolerance: 0.2,
			throwResistance: 2000,
			maxDuration: 1,
			edgeResistance: 0.9,
			dragClickables: true,
			zIndexBoost: false,
			bounds: this.getBounds(),
			cursor: 'pointer'
		})[0];

		this._activeTab = this._tabs[0];
	}

	private getBounds() {
		const boundsElement: HTMLElement = this.element.querySelector('.tabs-drag-bounds');
		const lastElement: HTMLElement = this._tabs[this._tabs.length - 1].element;
		const scrollAreaWidth = lastElement.offsetLeft + lastElement.offsetWidth;
		const bounds = { minX: 0, maxX: boundsElement.offsetWidth - scrollAreaWidth };

		return bounds;
	}

	private activateTab(tab) {
		this._tabs.forEach(loopedTab => {
			if (loopedTab.getId() == tab.getId()) {
				loopedTab.activate();

				TweenMax.to(this._textContainer, 0.3, { height: tab.getContentHeight() });

				this._activeTab = tab;
			} else {
				loopedTab.deactivate();
			}
		});
	}

	public resize() {
		super.resize();

		TweenMax.set(this._textContainer, { height: this._activeTab.getContentHeight() });
		this._draggable.vars.bounds = this.getBounds();

		if (WindowManager.width > WindowManager.BREAKPOINT_TABLET) {
			this._draggable.disable();
			TweenMax.set(this._draggable, { x: 0 });
			TweenMax.set(this._tabsContainer, { x: 0 });
		} else {
			this._draggable.enable();
		}
	}
}
