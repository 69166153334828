import { TimelineDesktopSlider } from './timeline/TimelineDesktopSlider';
import { NextPrevButtons } from './timeline/NextPrevButtons';
import { TimelineMobileSlider } from './timeline/TimelineMobileSlider';
import { Carousel } from './carousel/Carousel';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { TimelineElement } from './timeline/TimelineElement';
import { YearTimelineElement } from './timeline/YearTimelineElement';
import { WindowManager } from '../utils/WindowManager';
import { TweenMax, Power2 } from 'gsap/TweenMax';
import { Globals } from '../utils/Globals';

export class TimelineModule extends Carousel {
	public onDragged: Signal;
	public onMoved: Signal;

	private _sections: TimelineElement[];
	private _nextPrevButtons: NextPrevButtons;

	private _desktopSlider: TimelineDesktopSlider;
	private _mobileSlider: TimelineMobileSlider;

	// private years:YearTimelineElement[] = [];

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element.querySelector('.scrolled-area'), element.querySelector('.timeline-container'), element, template);
	}

	public getSections(): TimelineElement[] {
		return this._sections;
	}

	private createSections() {
		let sections = this.element.querySelectorAll('.scrolled-area > .TimelineModule--item');

		this._sections = [];

		sections.forEach(section => {
			if (section.classList.contains('year-section')) {
				let year = new YearTimelineElement(section as HTMLElement);
				// this.years.push(year);
				this._sections.push(year);
			} else {
				this._sections.push(new TimelineElement(section as HTMLElement));
			}
		});
	}

	protected prepare() {
		this.createSections();

		this.onDragged = new Signal();
		this.onMoved = new Signal();

		super.prepare();

		if (this.element.querySelector('.desktop-timeline-slider') != null) {
			this._desktopSlider = new TimelineDesktopSlider(this.element.querySelector('.desktop-timeline-slider'), this);
		}

		if (this.element.querySelector('.mobile-timeline-slider') != null) {
			this._mobileSlider = new TimelineMobileSlider(this.element.querySelector('.mobile-timeline-slider'), this);
		}

		if (this.element.querySelector('.carousel-next-prev-buttons') != null) {
			this._nextPrevButtons = new NextPrevButtons(this.element.querySelector('.carousel-next-prev-buttons'), this);
		}

		this.resize();
	}

	protected dragged() {
		super.dragged();
		let max = this.draggable.minX;
		if (Globals.RTL) {
			max = -this.draggable.maxX;
		}
		let percentage = (this.draggable.x / max) * -1;
		this.onDragged.dispatch(percentage);
		this.dispatchMoved();
	}

	public goToPercentage(xPercentagePos: number) {
		let max = this.draggable.minX;
		if (Globals.RTL) {
			max = this.draggable.maxX;
		}
		this.gotoLocation(max * xPercentagePos);
	}

	public getPosFromPercentage(xPos: number): number {
		return xPos * this.getScrollWidth();
	}

	private gotoLocation(xPixelPos: number) {
		TweenMax.set(this.draggable, { x: xPixelPos });
		TweenMax.set(this.draggedArea, { x: xPixelPos });

		this.dispatchMoved();
	}

	private dispatchMoved() {
		let newPosition = this.draggable.x / this.draggable.minX;
		if (Globals.RTL) {
			newPosition = this.draggable.x / this.draggable.maxX;
		}
		this.onMoved.dispatch(newPosition);
	}

	public getXPercentagePosOf(timelineElement: TimelineElement): number {
		let max = (this.draggable.minX - this.carouselContainer.clientWidth) * -1;
		if (Globals.RTL) {
			max = this.draggable.maxX + this.carouselContainer.clientWidth;
		}
		// console.log('max', max);
		let percentage = timelineElement.getXPos() / max;
		/*		if (Globals.RTL) {
					percentage = 1 - percentage;
					if (percentage < 0) {
						percentage = 0;
					}
				}*/

		// if (percentage > 1) percentage = 1;

		return percentage;
	}

	public tweenToElement(timelineElement: TimelineElement, instant: boolean = false) {
		const animTime = 0.6;

		let posToGoTo = -timelineElement.getXPos();
		if (Globals.RTL) {
			posToGoTo = posToGoTo + this.getWidth();
		}

		if (posToGoTo < this.draggable.minX) {
			posToGoTo = this.draggable.minX;
		}
		if (Globals.RTL) {
			if (posToGoTo > this.draggable.maxX) {
				posToGoTo = this.draggable.maxX;
			}
		}

		if (!instant) {
			TweenMax.to(this.draggable, animTime, { x: posToGoTo, ease: Power2.easeOut });
			TweenMax.to(this.draggedArea, animTime, { x: posToGoTo, onUpdate: this.dragged, onUpdateScope: this, ease: Power2.easeOut });
		} else {
			this.gotoLocation(posToGoTo);

			this.dragged();
		}
	}

	public resize() {
		let yearWidth = -1;

		if (WindowManager.width > WindowManager.BREAKPOINT_TABLET) {
			yearWidth = this.element.clientWidth + 20;
			yearWidth /= 6;
		}

		this._sections.forEach(section => {
			if (section instanceof YearTimelineElement) {
				section.resize(yearWidth);
			}
		});

		this.updateSnapPoints();
		this.draggable.vars.snap = this.getSnapPoints();

		super.resize();

		if (this._desktopSlider != null) {
			this._desktopSlider.resize();
		}

		if (this._mobileSlider != null) {
			this._mobileSlider.resize();
		}

		this.dragged();
	}

	protected getBounds() {
		let bounds = Globals.RTL
			? { minX: 0, maxX: this.draggedArea.scrollWidth - this.carouselContainer.clientWidth }
			: { minX: 0, maxX: -(this.draggedArea.scrollWidth - this.carouselContainer.clientWidth) };
		return bounds;
	}

	private getScrollWidth(): number {
		// if (Globals.RTL) {
		// 	return this.draggedArea.scrollWidth;
		// }
		return this.draggedArea.scrollWidth - this.element.offsetWidth;
	}

	protected getWidth(): number {
		if (Globals.RTL) {
			return this.draggedArea.clientWidth;
		}
		let lastItem = this._sections[this._sections.length - 1];
		return lastItem.getElement().offsetLeft + lastItem.getElement().offsetWidth;
	}

	protected getSnapPoints(): number[] {
		let snapPoints = [];

		let max = this.getWidth() - this.getScrollAreaWidth();
		let maxExceeded: Boolean = false;

		this._sections.forEach(element => {
			if (element.getElement().offsetLeft <= max) {
				snapPoints.push(-element.getElement().offsetLeft);
			} else {
				maxExceeded = true;
			}
		});

		if (Globals.RTL) {
			snapPoints = snapPoints.reverse();
		}
		if (maxExceeded) {
			snapPoints.push(-this.getWidth() + this.getScrollAreaWidth());
		}
		if (Globals.RTL) {
			snapPoints = snapPoints.map(point => point * -1);
		}
		// console.log(snapPoints, max);

		return snapPoints;
	}

	public kill() {
		if (this._nextPrevButtons != null) {
			this._nextPrevButtons.kill();
		}

		super.kill();
	}
}
