import { TimelineElement } from './TimelineElement';
import { TimelineModule } from '../TimelineModule';
import { Globals } from '../../utils/Globals';

export abstract class Dot {
	private _element: HTMLElement;
	private _xPos: number;

	constructor(protected timelineElement: TimelineElement, protected timeline: TimelineModule) {
		this._element = this.createDot(timelineElement);
	}

	protected abstract createDot(timelineElement: TimelineElement): HTMLElement;

	public getElement(): HTMLElement {
		return this._element;
	}

	public position(containerWidth: number) {
		this._xPos = this.timeline.getXPercentagePosOf(this.timelineElement);
		// if (Globals.RTL) {
		// 	this._element.style.right = containerWidth - (containerWidth * this._xPos) + 'px';
		// } else {
		console.log(containerWidth);
		this._element.style.left = containerWidth * this._xPos + 'px';
		// }
	}

	public getXPos(): number {
		return this._xPos;
	}

	public setPassed() {
		this._element.classList.add('passed');
	}

	public setNotPassed() {
		this._element.classList.remove('passed');
	}
}
