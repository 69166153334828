import { Globals } from '../../../../src/utils/Globals';
import { TemplateManager } from '../templateManager/TemplateManager';
import has = Reflect.has;

export class LinkParser {
	private static instance: LinkParser;
	public static templateManager: TemplateManager;

	private constructor(templateManager: TemplateManager) {
		LinkParser.templateManager = templateManager;
	}

	public static getInstance(templateManager?: TemplateManager): LinkParser {
		if (!LinkParser.instance) {
			if (!templateManager) {
				console.error('First LinkParser.getInstance must receive a TemplateManager instance as the first parameter');
			}
			LinkParser.instance = new LinkParser(templateManager);
		}

		return LinkParser.instance;
	}

	parse(element: HTMLElement, callback?: Function) {
		if (callback) {
			(element as any)._callback = callback;
		}

		element.addEventListener('click', this.onClick);
	}

	parseNodeList(nodeList: NodeListOf<HTMLElement>) {
		for (var i = 0; i < nodeList.length; i++) {
			this.parse(nodeList[i]);
		}
	}

	kill(element) {
		element.removeEventListener('click', this.onClick);

		if (element._callback) {
			element._callback = null;
		}
	}

	killNodeList(nodeList: NodeListOf<HTMLElement>) {
		for (var i = 0; i < nodeList.length; i++) {
			this.kill(nodeList[i]);
		}
	}

	onClick = event => {
		// Make sure that open in new tab is still allowed.
		if (
			event.ctrlKey ||
			event.shiftKey ||
			event.metaKey || // apple
			(event.button && event.button == 1) // middle click, >IE9 + everyone else
		) {
			return;
		} else {
			event.preventDefault();
		}

		if (event.currentTarget._callback) {
			event.currentTarget._callback(event.currentTarget);
		}

		var href = event.currentTarget.href || event.target.parentNode.href;

		if (href === undefined) {
			href = '';
		}


		if (this.isInternal(href)) {
			var base = document.location.protocol + '//' + window.location.host + '/';
      		var path = href.replace(base, '');
      		// Download media.
			if (path.indexOf('download/media') !== -1) {
			  window.location.href = href;
			}
			// Covid care landing page.
			else if (path.indexOf('covid-19-care') !== -1 && path.indexOf('covid-19-video-kits') === -1
			&& path.indexOf('covid-19-audio-kit') === -1 && path.indexOf('covid-19-ebooks') === -1) {
			  window.location.href = href;
			}
			else {
        		LinkParser.templateManager.path(path);
			}
		} else {
			if (href.indexOf('mailto') !== -1) {
				window.location.href = href;
			} else {
				window.open(href, '_blank');
			}
		}
	};

	click(href) {

		if (this.isInternal(href)) {
			var base = document.location.protocol + '//' + window.location.host + '/';

			var path = href.replace(base, '');

			LinkParser.templateManager.path(path);
		} else {
			if (href.indexOf('mailto') !== -1) {
				window.location.href = href;
			} else {
				window.open(href, '_blank');
			}
		}
	}


	isInternal(url) {
		var valid = false;

		//@lasse TODO: does hits make sense anymore?
		var l = Globals.WHITELISTED_DOMAINS.length;


		for (var i = 0; i < l; i++) {
			if (url.indexOf(Globals.WHITELISTED_DOMAINS[i]) !== -1) {
				valid = true;
				break;
			}
		}

		if (isFile(url)) {
			valid = false;
		}

		return valid;
	}
}

export function isFile(pathname: string) {
	return (
		pathname
			.split('/')
			.pop()
			.split('.').length > 1
	);
}
