import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { TweenMax, Power1 } from 'gsap/TweenMax';

export class CareerOpportunitiesListModule extends AbstractModule {
	private _showMoreButton: HTMLElement;
	private _jobs: NodeListOf<HTMLElement>;

	private _currentlyShown: number = 0;
	private _incrementNumber: number = 0;

	private _animationTime: number = 0.1;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._showMoreButton = element.querySelector('.show-more-button');
		this._jobs = element.querySelectorAll('.ListItem');

		this._jobs.forEach(job => {
			if (!job.classList.contains('hidden')) {
				this._currentlyShown++;
			}
		});

		this._incrementNumber = this._currentlyShown;

		if (this._showMoreButton != null) {
			this._showMoreButton.addEventListener('click', this.showMore);
		}
	}

	private showMore = () => {
		let additionalShown = 0;
		console.log('showing');

		this._jobs.forEach(job => {
			if (additionalShown < this._incrementNumber && job.classList.contains('hidden')) {
				TweenMax.delayedCall(this._animationTime * additionalShown * 0.9, this.showItem, [job], this);
				console.log('showing');
				additionalShown++;
				this._currentlyShown++;
			}
		});

		if (this._currentlyShown >= this._jobs.length) {
			this._showMoreButton.classList.add('disabled');
		}
	};

	private showItem(job: HTMLElement = null) {
		TweenMax.to(job, this._animationTime, { className: '-=hidden', ease: Power1.easeInOut });
	}
}
