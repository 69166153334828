import axios, { CancelTokenSource } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import * as Qs from 'qs';

interface SwiftypeAPIOptions {
	per_page?: number;
	spelling?: string;
	//engine_key?: string;
	type?: string;
	filters?: object;
	search_fields?: object;
	page?: number;
	fetch_fields?: object;
	facets?: object;
	document_types?: object;
	functional_boosts?: object;
	sort_field?: object;
	sort_direction?: object;
	highlight_fields?: object;
}

export interface Page {
	title: string;
	type: string;
	external_id: string;
	image: string;
	url: string;
	field_date?: string;
	published_at: Date;
	updated_at: Date;
	popularity: number;
	body: string;
	info: string;
	_index: string;
	_type: string;
	_score: number;
	_version?: any;
	_explanation?: any;
	sort?: any;
	highlight: any;
	nothing: string;
	search_api_excerpt: any;
  rendered_item: string;
	id: string;
	description?: string;
	field_image_url: string;
}

interface Records {
	page: Page[];
}

interface Facets {}

interface SpellingSuggestion {
	text: string;
	score: number;
}

interface Page2 {
	query: string;
	current_page: number;
	num_pages: number;
	per_page: number;
	total_result_count: number;
	spelling_suggestion?: SpellingSuggestion;
	facets: Facets;
}

interface Info {
	page: Page2;
}

interface Errors {}

export interface SwiftypeAPIResponse {
	record_count: number;
	records: Records;
	info: Info;
	errors: Errors;
	config: any;
}

export class SwiftypeAPI {
	//private static ROOT_URL = 'https://api.swiftype.com';

	private cancelRequest: CancelTokenSource = null;
	private currReqest = null;

	private http = axios.create({
		adapter: cacheAdapterEnhancer(axios.defaults.adapter),
		paramsSerializer: function(params) {
			return Qs.stringify(params, { arrayFormat: 'brackets' });
		}
	});

	private defaultConfig: SwiftypeAPIOptions = {
		//per_page: 1000,
		page: 0,
		//spelling: 'strict',
		//engine_key: '',
		//filters: undefined,
		//search_fields: undefined
	};

	constructor(engineKey: string) {
		this.defaultConfig.type = engineKey;
	}

	public query(query: string, callback: (response: SwiftypeAPIResponse) => void, config: SwiftypeAPIOptions = {}) {
		this.abort();

		let configMerge = { ...this.defaultConfig, ...config };
		configMerge['q'] = query;

		this.cancelRequest = axios.CancelToken.source();

		let options = {
			cancelToken: this.cancelRequest.token,
			withCredentials: true,
			params: configMerge
		};

		this.currReqest = this.http
			.get('/site/search', options)
			.then(response => {
				this.cancelRequest = null;
				let responseFiltersMerged = response.data;
				responseFiltersMerged.config = config;
				callback(responseFiltersMerged as SwiftypeAPIResponse);
			})
			.catch(thrown => {
				if (axios.isCancel(thrown)) {
					// console.log('Request canceled', thrown.message);
				} else {
					// handle error
				}
				this.cancelRequest = null;
			});
	}

	public abort = () => {
		if (this.cancelRequest) {
			console.log('cancel', this.cancelRequest);
			this.cancelRequest.cancel();
			this.cancelRequest = null;
		}
	};
}
