import { AbstractModule } from '../../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../../templates/AbstractTemplate';
import { TweenMax } from 'gsap/TweenMax';
import { Globals } from '../../utils/Globals';
import VideoJSWrapper from '../../videojs/VideoJSWrapper';

export class HeroModule extends AbstractModule {
	private _scrollButton: HTMLElement;
	private _element: HTMLElement;

	private refVideo: HTMLVideoElement;
	private refPoster: HTMLDivElement;
	private refCloseButton: HTMLDivElement;

	private videojs: any = null;
	private videoURL: string;

	private videoContainer: HTMLDivElement;
	private videoContainerInner: HTMLDivElement;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._element = element;

		if (Globals.IS_IE) {
			let img = this._element.querySelector('picture img');
			if (img) {
				(<any>window).lazySizes.fire(img, 'lazyunveilread');
			}
		}

		if (this._element.dataset.video) {
			this.createVideoElement();
		}
		this._scrollButton = element.querySelector('.scroll');
		if (this._scrollButton) {
			this._scrollButton.addEventListener('click', this.arrowClickHandler);
		}
	}

	private createVideoElement() {
		this.videoURL = this._element.dataset.video;
		this.refVideo = document.createElement('video');
		this.refVideo.controls = true;
		this.refVideo.preload = 'auto';
		this.refVideo.autoplay = true;
		this.refVideo.classList.add('video-js');

		let videoSrc = document.createElement('source');
		videoSrc.src = this.videoURL;
		videoSrc.type = 'application/x-mpegURL';

		this.refVideo.appendChild(videoSrc);

		this.videoContainer = document.createElement('div');
		this.videoContainer.classList.add('HeroModule__video-popup');

		this.videoContainerInner = document.createElement('div');
		this.videoContainerInner.classList.add('HeroModule__video-popup__inner');
		this.videoContainer.appendChild(this.videoContainerInner);

		this.videoContainerInner.appendChild(this.refVideo);

		this.refCloseButton = document.createElement('div');
		this.refCloseButton.innerHTML = `<svg width="40" height="40" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="20" cy="20" r="20"/><rect fill="#191919" transform="rotate(45 20 20)" x="12" y="19" width="16" height="2" rx="1"/><rect fill="#191919" transform="rotate(135 20 20)" x="12" y="19" width="16" height="2" rx="1"/></g></svg>`;
		this.refCloseButton.classList.add('HeroModule__video-popup__close-button');
		this.videoContainerInner.appendChild(this.refCloseButton);

		this.refPoster = this._element.querySelector('.header-image');
		this.refPoster.addEventListener('click', this.posterClickHandler);
		this.refCloseButton.addEventListener('click', this.closeClickHandler);
		this.videoContainer.addEventListener('click', this.videoContainerClickHandler);
	}

	private posterClickHandler = event => {
		if (event) {
			event.preventDefault();
		}

		if (!this.videojs) {
			document.documentElement.addEventListener('keyup', this.keyUpHandler);
			this._element.appendChild(this.videoContainer);
			new VideoJSWrapper(this.refVideo, this);
		} else {
			this.videojs.play();
		}
		TweenMax.to(this.videoContainer, 0.3, { autoAlpha: 1, pointerEvents: 'all' });
	};

	private closeClickHandler = event => {
		if (event) {
			event.preventDefault();
		}
		document.documentElement.removeEventListener('keyup', this.keyUpHandler);
		if (this.videojs) {
			this.videojs.pause();
		}
		TweenMax.to(this.videoContainer, 0.3, { autoAlpha: 0, pointerEvents: 'none' });
	};

	private videoContainerClickHandler = event => {
		if (event.target === this.videoContainer) {
			this.closeClickHandler(event);
		}
	};

	private arrowClickHandler = () => {
		TweenMax.to(window, 0.4, { scrollTo: this._element.offsetHeight + this._element.offsetTop });
	};

	private keyUpHandler = event => {
		if (event.key === 'Escape') {
			this.closeClickHandler(event);
		}
	};

	public kill() {
		if (this._scrollButton != null) {
			this._scrollButton.removeEventListener('click', this.arrowClickHandler);
		}

		if (this.videojs) {
			document.documentElement.removeEventListener('keyup', this.keyUpHandler);
			this.refPoster.removeEventListener('click', this.posterClickHandler);
			this.refCloseButton.removeEventListener('click', this.closeClickHandler);
			this.videojs.dispose();
		}

		super.kill();
	}
}
