import { AbstractTemplate } from '../templates/AbstractTemplate';
import { TweenMax, Power1, Back } from 'gsap/TweenMax';
import { IntersectionObserverModule } from '../general/IntersectionObserverModule';

export class CircularProgressBarModule extends IntersectionObserverModule {
	private _bigRing: SVGPathElement;
	private _smallRing: SVGPathElement;

	private _smallRingPercentage: number;

	private _animationTime: number = 0.6;
	private _groverImage: HTMLElement;

	private _smallText: HTMLElement;
	private _bigText: HTMLElement;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._bigRing = element.querySelector('.progress-ring.big');
		this._smallRing = element.querySelector('.progress-ring.small');

		this.hide();

		this._bigText = element.querySelector('.left');
		this._smallText = element.querySelector('.right');

		this._groverImage = element.querySelector('.center-image>img');

		this._smallRingPercentage = 100 - parseInt(element.querySelector('h4.small').innerHTML);

		this.observe(element.querySelector('.ring-segment'));
	}

	protected elementIntersectFound(element: Element) {
		this.show();
	}

	protected getOptions(): IntersectionObserverInit {
		return { threshold: [0.4] };
	}

	private hide() {
		const smallRingSVG = this._smallRing.querySelector('svg path');
		const bigRingSVG = this._bigRing.querySelector('svg path');

		TweenMax.set(smallRingSVG, { drawSVG: '100% 100%' });
		TweenMax.set(bigRingSVG, { drawSVG: '100% 100%' });
	}

	private show = () => {
		TweenMax.to(this._groverImage, this._animationTime, { className: '-=hidden', delay: this._animationTime * 1.2, ease: Back.easeOut.config(0.7) });

		TweenMax.to(this._bigText, this._animationTime, { className: '-=hidden', delay: this._animationTime * 0.7, ease: Back.easeOut.config(0.7) });
		TweenMax.to(this._smallText, this._animationTime, { className: '-=hidden', delay: this._animationTime * 0.9, ease: Back.easeOut.config(0.7) });

		const smallRingSVG = this._smallRing.querySelector('svg path');
		const bigRingSVG = this._bigRing.querySelector('svg path');

		this._smallRing.classList.remove('hidden');
		this._bigRing.classList.remove('hidden');

		TweenMax.to(bigRingSVG, this._animationTime, { drawSVG: '0% 100%', ease: Power1.easeIn, onComplete: this.removeGreyRing, onCompleteScope: this });
		TweenMax.to(smallRingSVG, this._animationTime * ((100 - this._smallRingPercentage) * 0.02), {
			drawSVG: this._smallRingPercentage + '% 100%',
			delay: this._animationTime,
			ease: Power1.easeOut
		});
	};

	private removeGreyRing() {
		(this.element.querySelector('.grey') as HTMLElement).style.display = 'none';
	}
}
