import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { TweenMax } from 'gsap/TweenMax';

export class MenuButton {
	public onClick: Signal = new Signal();
	private _button: HTMLElement;

	private _topLine: SVGElement;
	private _middleLine: SVGElement;
	private _bottomLine: SVGElement;

	private _showingShadow = false;

	constructor(button: HTMLElement) {
		console.log('setupButton');
		this._button = button;
		this._button.addEventListener('click', this.click);

		this._topLine = this._button.querySelector('.burger-icon svg #top-line');
		this._middleLine = this._button.querySelector('.burger-icon svg #middle-line');
		this._bottomLine = this._button.querySelector('.burger-icon svg #bottom-line');
	}

	private click = event => {
		if (event) {
			event.preventDefault();
		}
		this.onClick.dispatch();
	};

	private killTweens() {
		TweenMax.killDelayedCallsTo(this.hideMiddleLine);
		TweenMax.killDelayedCallsTo(this.showMiddleLine);
		TweenMax.killTweensOf(this._topLine);
		TweenMax.killTweensOf(this._bottomLine);
	}

	public showClose(instant: boolean) {
		this.killTweens();

		if (!instant) {
			TweenMax.to(this._topLine, 0.2, { y: 5 });
			TweenMax.to(this._bottomLine, 0.2, { y: -5 });
			TweenMax.delayedCall(0.2, this.hideMiddleLine, null, this);

			TweenMax.to(this._topLine, 0.2, { rotation: 45, transformOrigin: 'center center', delay: 0.2 });
			TweenMax.to(this._bottomLine, 0.2, { rotation: -45, transformOrigin: 'center center', delay: 0.2 });
		} else {
			this.hideMiddleLine();

			TweenMax.set(this._topLine, { y: -5, rotation: 45, transformOrigin: 'center center', delay: 0.2 });
			TweenMax.set(this._bottomLine, { y: -5, rotation: -45, transformOrigin: 'center center', delay: 0.2 });
		}
	}

	public showBurger(instant: boolean = false) {
		this.killTweens();

		if (!instant) {
			TweenMax.to(this._topLine, 0.2, { y: 0, delay: 0.2 });
			TweenMax.to(this._bottomLine, 0.2, { y: 0, delay: 0.2 });

			TweenMax.delayedCall(0.2, this.showMiddleLine, null, this);

			TweenMax.to(this._topLine, 0.2, { rotation: 0, transformOrigin: 'center center' });
			TweenMax.to(this._bottomLine, 0.2, { rotation: -0, transformOrigin: 'center center' });
		} else {
			this.showMiddleLine();

			TweenMax.set(this._topLine, { clearProps: 'transform' });
			TweenMax.set(this._bottomLine, { clearProps: 'transform' });
		}
	}

	private hideMiddleLine() {
		this._middleLine.style.visibility = 'hidden';
	}

	private showMiddleLine() {
		this._middleLine.style.visibility = 'visible';
	}

	public updateScroll(y) {
		if (y > 30 && !this._showingShadow) {
			this.showShadow();
		} else if (y <= 30 && this._showingShadow) {
			this.hideShadow();
		}
	}

	public showShadow() {
		TweenMax.to(this._button, 0.2, { className: '+=scrolled' });
		this._showingShadow = true;
	}

	public hideShadow() {
		TweenMax.to(this._button, 0.2, { className: '-=scrolled' });
		this._showingShadow = false;
	}
}
