import { Button } from './Button';
import { CarouselItem } from '../modules/carousel/CarouselItem';

export class CarouselDotButton extends Button {
	private _carouselItem: CarouselItem;

	constructor(element: HTMLElement, carouselItem: CarouselItem) {
		super(element);

		this._carouselItem = carouselItem;

		this.onClick.add(this.dotClicked, this);
	}

	private dotClicked() {
		this._carouselItem.fireScrollToItem();
	}

	public getCarouselItem(): CarouselItem {
		return this._carouselItem;
	}

	public setActive() {
		this.element.classList.add('active');
	}

	public setInactive() {
		this.element.classList.remove('active');
	}
}
