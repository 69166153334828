import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { WindowManager } from '../utils/WindowManager';

export class DonateButton {
	public onClick = new Signal();

	private _aTag: HTMLAnchorElement;
	private _link: string;

	constructor(button: HTMLElement) {
		this._aTag = button.querySelector('a');
		this._link = this._aTag.href;

		WindowManager.signalResize.add(this.onResize, this);
	}

	public setDesktop() {
		this._aTag.href = this._link;
		this._aTag.removeEventListener('click', this.click);
	}

	protected click = (e: MouseEvent) => {
		e.preventDefault();
		this.onClick.dispatch();
	};

	public setMobile() {
		//set button to desktop
		this._aTag.href = this._link;
		this._aTag.addEventListener('click', this.click);
	}

	private onResize() {
		if (WindowManager.isMobile()) {
			this.setMobile();
		} else {
			this.setDesktop();
		}
	}
}
