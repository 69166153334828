import { MapElement } from './MapElement';
import { TweenMax, Power1 } from 'gsap/TweenMax';
import { TimelineMax } from 'gsap/TimelineMax';

export class Wave extends MapElement {
	private _timeline: TimelineMax;

	constructor(element: SVGPathElement) {
		super(element);

		this.onShown.addOnce(this.startRocking, this);
	}

	public getShowDrawEnd(): string {
		return '0% 80%';
	}

	public show(delay: number = 0, timeMultiplier: number = 1) {
		super.show(delay, timeMultiplier);

		TweenMax.to(this.element, this.getAnimationTime() * timeMultiplier, { x: 3 });
	}

	public startRocking() {
		this._timeline = new TimelineMax({ repeat: -1 });

		this._timeline.to(this.element, 3, { x: -3, ease: Power1.easeInOut, drawSVG: '20% 100%' }).to(this.element, 3, { x: 3, ease: Power1.easeInOut, drawSVG: '0% 80%' });
	}

	public kill() {
		this._timeline.stop();

		TweenMax.killDelayedCallsTo(this.startRocking);
	}
}
