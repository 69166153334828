export class IconGridItem {
	private _textElement: HTMLElement;
	private _stringLength: number;
	private _rawString: string;

	private _currentHeight: number;

	private _singleLineHeight: number = 22;

	constructor(element: HTMLElement) {
		this._textElement = element.querySelector('p');
		this._stringLength = this._textElement.innerText.length;
		this._rawString = this._textElement.innerText;
		this._currentHeight = this._textElement.offsetHeight;

		this.splitLines();
		this.resize();
	}

	private splitLines() {
		let words = this._rawString.split(' ');

		let newString = '';
		let currentLetterCount = 0;
		let newlineInserted = false;

		words.forEach(word => {
			currentLetterCount += word.length;

			newString += word + ' ';

			if (!newlineInserted && currentLetterCount > this._stringLength * 0.4) {
				newString += '<br />';
				newlineInserted = true;
			}
		});

		this._textElement.innerHTML = newString;

		if (this._textElement.offsetHeight > this._singleLineHeight * 2) {
			this._textElement.innerHTML = this._rawString;
		}

		this._currentHeight = this._textElement.offsetHeight;
	}

	public resize() {
		let newHeight = this._textElement.offsetHeight;

		if (this._currentHeight == newHeight) {
			return;
		}

		if (newHeight > this._singleLineHeight * 2) {
			if (this._currentHeight > this._singleLineHeight * 2) return;

			this._textElement.innerHTML = this._rawString;
			this._currentHeight = this._textElement.offsetHeight;

			return;
		}

		this.splitLines();

		this._currentHeight = this._textElement.offsetHeight;
	}
}
