import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import * as Qs from 'qs';

export class NewsletterSignupModule extends AbstractModule {
	private formElement: HTMLFormElement;
	private emailInput: HTMLInputElement;
	private submitButton: HTMLInputElement;

	private submittingForm = false;

	private defaultSubmitValue = '';

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this.formElement = element.querySelector('form');
		this.submitButton = element.querySelector('input[type="submit"]');
		this.emailInput = element.querySelector('input[type="email"]');
		this.defaultSubmitValue = this.submitButton.value;
		this.formElement.addEventListener('submit', this.submitHandler);
	}

	private submitHandler = (e: Event) => {
		if (e) {
			e.preventDefault();
		}

		if (!this.submittingForm) {
			let email = this.emailInput.value;
			this.submittingForm = true;
			window.Main.templateManager.path(this.formElement.getAttribute('action').substring(1) + '?' + Qs.stringify({ email: email }));
		}

		return false;
	};

	public kill() {
		super.kill();
		this.formElement.removeEventListener('submit', this.submitHandler);
	}
}
