import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import * as Qs from 'qs';
import axios, { CancelTokenSource } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

export class DonateModule extends AbstractModule {
	private monthlyCheckbox: HTMLInputElement;
	private perMonth: HTMLInputElement;
	private otherAmount: HTMLInputElement;
	private otherAmountError: HTMLElement;
	private panFieldWrapper: HTMLElement;
	private radioOptions: NodeListOf<HTMLInputElement>;
	private name: HTMLInputElement;
	private email: HTMLInputElement;
	private pan: HTMLInputElement;
	private mobile: HTMLInputElement;
	private address: HTMLInputElement;
	private pinCode: HTMLInputElement;
	private state: HTMLInputElement;
	private city: HTMLInputElement;
	private donateButton: HTMLInputElement;
	private paymentType: string;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);
		element.querySelectorAll('form').forEach(form => {
			form.addEventListener('submit', this.submitHandler);
		});
		// Adding iframe pop-up script here. Ideally it should be in some global file.
		if (document.getElementsByClassName('iframe-lightbox-link').length > 0) {
			[].forEach.call(document.getElementsByClassName('iframe-lightbox-link'), function(el) {
				el.lightbox = new IframeLightbox(el);
			});
		}
		this.monthlyCheckbox = element.querySelector('input[name=donate-monthly]');
		this.perMonth = element.querySelector('#donationAmountContMonth');
		this.otherAmount = element.querySelector('#other');
		this.otherAmountError = element.querySelector('#other-error');
		this.panFieldWrapper = element.querySelector('#pan-field-wrapper');
		this.radioOptions = element.querySelectorAll('.donate-choices input');
		// Payment form fields.
		this.email = element.querySelector('#email');
		this.pan = element.querySelector('#pan');
		this.mobile = element.querySelector('#mobile');

		this.name = element.querySelector('#name');
		this.address = element.querySelector('#address');
		this.pinCode = element.querySelector('#pincode');
		this.state = element.querySelector('#state');
		this.city = element.querySelector('#city');
		this.donateButton = element.querySelector('#donationBtn');
		this.paymentType = 'onetime';

		// Check query string paramaters.
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('donate-amount') && urlParams.get('donate-amount') !== null) {
			let queryStringDonateAmount = urlParams.get('donate-amount');
			this.radioOptions.forEach(option => {
				if (option.value == queryStringDonateAmount) {
					option.checked = true;
				}
				this.otherAmount.value = queryStringDonateAmount;
			});
		}

		/*if (this.email !== null) {
			this.email.addEventListener('keyup', () => {
				this.email.setCustomValidity("");
				if (!this.email.checkValidity()) {
					this.email.setCustomValidity("Please enter a valid email address.");
					this.email.nextElementSibling.innerHTML = '';
				}
				else {
					this.email.setCustomValidity("");
					this.email.nextElementSibling.innerHTML = 'Please enter a valid email address.';
				}
			});
		}*/

		/*this.mobileCountry.addEventListener('focusin', () => {
			this.mobileCountry.nextElementSibling.innerHTML = '';
		});*/
		/*if (this.mobileCountry !== null) {
			this.mobileCountry.addEventListener('keyup', () => {
				this.mobileCountry.setCustomValidity("");
				if (!this.mobileCountry.checkValidity()) {
					this.mobileCountry.setCustomValidity("Please enter country code in required format. e.g. +91");
				}
				else {
					this.mobileCountry.setCustomValidity("");
				}
			});
		}*/

		/*if (this.mobile !== null) {
			this.mobile.addEventListener('keyup', () => {
				this.mobile.setCustomValidity("");
				if (!this.mobile.checkValidity()) {
					this.mobile.setCustomValidity("Please enter a 10 digit mobile number.");
				}
				else {
					this.mobile.setCustomValidity("");
				}
			});
		}*/

		if (this.email !== null) {
			this.email.addEventListener('keyup', () => {
				this.email.nextElementSibling.innerHTML = '';
				(<any>this).email.nextElementSibling.style.display = 'none';
			});
		}

		if (this.pan !== null) {
			this.pan.addEventListener('keyup', () => {
				this.pan.nextElementSibling.innerHTML = '';
				(<any>this).pan.nextElementSibling.style.display = 'none';
			});
		}

		if (this.mobile !== null) {
			this.mobile.addEventListener('keyup', () => {
				this.mobile.nextElementSibling.innerHTML = '';
				(<any>this).mobile.nextElementSibling.style.display = 'none';
			});
		}
		if (this.pinCode !== null) {
			this.pinCode.addEventListener('keyup', () => {
				this.pinCode.nextElementSibling.innerHTML = '';
				(<any>this).pinCode.nextElementSibling.style.display = 'none';
			});
		}
		if (this.name !== null) {
			this.name.addEventListener('keyup', () => {
				/* Show/Hide PAN field, this is to handle the case when user is taken to the donation
				page from a donation block.*/
				if (this.pan !== null && this.otherAmount.value != '') {
					let showPanFieldAmount: number = 10000;
					let amount: number = parseInt(this.otherAmount.value);
					if (amount >= showPanFieldAmount) {
						(<any>this).panFieldWrapper.style.display = 'block';
					} else {
						(<any>this).panFieldWrapper.style.display = 'none';
					}
				}

				this.name.nextElementSibling.innerHTML = '';
				(<any>this).name.nextElementSibling.style.display = 'none';
			});
		}
		if (this.address !== null) {
			this.address.addEventListener('keyup', () => {
				this.address.nextElementSibling.innerHTML = '';
				(<any>this).address.nextElementSibling.style.display = 'none';
			});
		}
		if (this.city !== null) {
			this.city.addEventListener('keyup', () => {
				this.city.nextElementSibling.innerHTML = '';
				(<any>this).city.nextElementSibling.style.display = 'none';
			});
		}
		if (this.state !== null) {
			this.state.addEventListener('keyup', () => {
				this.state.nextElementSibling.innerHTML = '';
				(<any>this).state.nextElementSibling.style.display = 'none';
			});
		}

		this.monthlyCheckbox.addEventListener('change', () => {
			if (this.monthlyCheckbox.checked) {
				this.paymentType = 'monthly';
				this.perMonth.style.display = 'block';
			} else {
				this.perMonth.style.display = 'none';
				this.paymentType = 'onetime';
			}
		});

		this.radioOptions.forEach(option => {
			option.addEventListener('change', () => {
				(<any>this).otherAmountError.style.display = 'none';
				this.otherAmountError.innerHTML = '';
				this.otherAmount.value = option.value;

				// Show/Hide PAN field.
				if (this.pan !== null) {
					let showPanFieldAmount: number = 10000;
					let amount: number = parseInt(option.value);
					if (amount >= showPanFieldAmount) {
						(<any>this).panFieldWrapper.style.display = 'block';
					} else {
						(<any>this).panFieldWrapper.style.display = 'none';
					}
				}

				//this.otherAmount.setCustomValidity("");
			});
		});

		this.otherAmount.addEventListener('keyup', () => {
			// Show/Hide PAN field.
			if (this.pan !== null) {
				let showPanFieldAmount: number = 10000;
				let amount: number = parseInt(this.otherAmount.value);
				if (amount >= showPanFieldAmount) {
					(<any>this).panFieldWrapper.style.display = 'block';
				} else {
					(<any>this).panFieldWrapper.style.display = 'none';
				}
			}

			(<any>this).otherAmountError.style.display = 'none';
			this.otherAmountError.innerHTML = '';
			this.radioOptions.forEach(option => {
				option.checked = false;
			});
		});
	}

	// Function to handle Razorpay payment and redirection.
	private makePayment(jsonData) {
		let redirectionUrl = jsonData.formUrl;
		let isMonthly = jsonData.isMonthly;
		let paymentType = this.paymentType;
		let url = '/payment/callbacks';
		let donationAmount = this.otherAmount.value;
		let subscriptionId = 0;
		let orderId = 0;
		let data = {};
		let options = {};
		let donateButton = this.donateButton;

		if (isMonthly) {
			data = { amount: donationAmount, razorpayCreatePlan: 1 };
		} else {
			data = { amount: donationAmount, razorpayOrder: 1 };
		}

		let donationDescription = 'Donation of INR ' + donationAmount;
		let razorPayKey = '';
		let name = this.name.value;
		let email = this.email.value;
		let pan = '';
		if (this.pan !== null && this.pan.value != '') {
			pan = this.pan.value;
		}
		let mobile = this.mobile.value;
		let address = this.address.value + ' ' + this.state.value + ' ' + this.city.value + ' ' + this.pinCode.value;
		fetch(url, {
			method: 'POST',
			body: JSON.stringify(data), // data can be `string` or {object}!
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(res => res.json())
			.then(function(data) {
				// Here you get the data to modify as you please
				if (isMonthly) {
					subscriptionId = data.razorpaySubscription;
				} else {
					orderId = data.razorpayOrder;
				}
				razorPayKey = data.razorpayKeyId;
				if (isMonthly) {
					donationDescription = 'Monthly Donation of INR ' + donationAmount;
					options = {
						key: razorPayKey, // Enter the Key ID generated from the Dashboard
						subscription_id: subscriptionId,
						currency: 'INR',
						name: 'Sesame Workshop India',
						description: donationDescription,
						handler: function(response) {
							// Verify the payment.
							fetch(url, {
								method: 'POST',
								body: JSON.stringify({ razorpayPaymentId: response.razorpay_payment_id, razorpayOrderId: 'subscription', razorpaySignature: response.razorpay_signature }), // data can be `string` or {object}!
								headers: {
									'Content-Type': 'application/json'
								}
							})
								.then(res => res.json())
								.then(function(data) {
									// Disable donate button.
									donateButton.value = 'Please wait...';
									donateButton.setAttribute('disabled', 'disabled');
									let additionalParams = {};
									additionalParams['subscription_id'] = subscriptionId;
									additionalParams['status'] = 1;
									let additionalParamsqueryString = Qs.stringify(additionalParams);
									// Redirect to thankyou page.
									window.Main.templateManager.path('donate/thank-you?' + additionalParamsqueryString);
								})
								//.catch(error => alert('Something went wrong. Please try again.'));
								.catch(function(error) {
									// Enable donate button.
									donateButton.value = 'Donate';
									donateButton.removeAttribute('disabled');
									alert('Something went wrong. Please try again.');
								});
						},
						/**
						 * You can track the modal lifecycle by * adding the below code in your options
						 */
						modal: {
							ondismiss: function() {
								// Enable donate button.
								donateButton.value = 'Donate';
								donateButton.removeAttribute('disabled');
							}
						},
						prefill: {
							name: name,
							email: email,
							contact: mobile
						},
						notes: {
							Address: address,
							PAN: pan,
							Name: name
						}
					};
				} else {
					options = {
						key: razorPayKey, // Enter the Key ID generated from the Dashboard
						amount: donationAmount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
						currency: 'INR',
						name: 'Sesame Workshop India',
						description: donationDescription,
						order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
						handler: function(response) {
							// Verify the payment.
							fetch(url, {
								method: 'POST',
								body: JSON.stringify({ razorpayPaymentId: response.razorpay_payment_id, razorpayOrderId: response.razorpay_order_id, razorpaySignature: response.razorpay_signature }), // data can be `string` or {object}!
								headers: {
									'Content-Type': 'application/json'
								}
							})
								.then(res => res.json())
								.then(function(data) {
									// Disable donate button.
									donateButton.value = 'Please wait...';
									donateButton.setAttribute('disabled', 'disabled');
									let additionalParams = {};
									additionalParams['order_id'] = orderId;
									additionalParams['status'] = 1;
									let additionalParamsqueryString = Qs.stringify(additionalParams);
									// Redirect to thankyou page.
									window.Main.templateManager.path('donate/thank-you?' + additionalParamsqueryString);
								})
								//.catch(error => alert('Something went wrong. Please try again.'));
								.catch(function(error) {
									// Enable donate button.
									donateButton.value = 'Donate';
									donateButton.removeAttribute('disabled');
									alert('Something went wrong. Please try again.');
								});
						},
						/**
						 * You can track the modal lifecycle by * adding the below code in your options
						 */
						modal: {
							ondismiss: function() {
								// Enable donate button.
								donateButton.value = 'Donate';
								donateButton.removeAttribute('disabled');
							}
						},
						prefill: {
							name: name,
							email: email,
							contact: mobile
						},
						notes: {
							Address: address,
							PAN: pan,
							Name: name
						}
					};
				}
				let razorPay = new Razorpay(options);
				razorPay.open();
			})
			.catch(function(error) {
				// Enable donate button.
				donateButton.value = 'Donate';
				donateButton.removeAttribute('disabled');
				alert('Something went wrong. Please try again.');
			});
	}

	private formFieldsValidate(formType) {
		let status = true;
		let maxRazorpayAmount: number = 500000;
		let amount: number = parseInt(this.otherAmount.value);
		let amountError: number = 0;
		let regPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
		let showPanFieldAmount: number = 10000;
		if (!amount || amount < 0) {
			this.otherAmountError.innerHTML = 'Please enter a valid amount or choose from the listed amounts.';
			(<any>this).otherAmountError.style.display = 'block';
			this.otherAmount.scrollIntoView({ behavior: 'smooth', block: 'center' });
			amountError = 1;
			status = false;
		} else {
			if (amount > maxRazorpayAmount) {
				this.otherAmountError.innerHTML = 'Maximum of INR 5,00,000.00 can be paid in a single transaction.';
				(<any>this).otherAmountError.style.display = 'block';
				this.otherAmount.scrollIntoView({ behavior: 'smooth', block: 'center' });
				amountError = 1;
				status = false;
			}
		}

		// Payment Form Validations
		if (formType == 'paymentForm') {
			let showPanField: number = 0;
			// Show/Hide PAN field.
			if (amount >= showPanFieldAmount) {
				(<any>this).panFieldWrapper.style.display = 'block';
				showPanField = 1;
			} else {
				(<any>this).panFieldWrapper.style.display = 'none';
			}

			if (this.name.value == '') {
				this.name.nextElementSibling.innerHTML = 'Please enter your name.';
				(<any>this).name.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.name.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			}
			if (this.mobile.value == '') {
				this.mobile.nextElementSibling.innerHTML = 'Please enter your mobile number.';
				(<any>this).mobile.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.mobile.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			} else {
				let mobileRegex = /[0-9]{10}/g;
				if (!this.mobile.value.match(mobileRegex)) {
					this.mobile.nextElementSibling.innerHTML = 'Please enter a valid 10 digit mobile number.';
					(<any>this).mobile.nextElementSibling.style.display = 'block';
					if (!amountError) {
						this.mobile.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
					status = false;
				}
			}
			if (this.email.value == '') {
				this.email.nextElementSibling.innerHTML = 'Please enter your email address.';
				(<any>this).email.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.email.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			} else {
				if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email.value)) {
					this.email.nextElementSibling.innerHTML = 'Please enter a valid email address.';
					(<any>this).email.nextElementSibling.style.display = 'block';
					if (!amountError) {
						this.email.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
					status = false;
				}
			}

			if (showPanField && this.pan.value == '') {
				this.pan.nextElementSibling.innerHTML = 'Please enter your PAN number.';
				(<any>this).pan.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.pan.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			} else {
				if (showPanField && !regPan.test(this.pan.value)) {
					this.pan.nextElementSibling.innerHTML = 'Please enter a valid PAN number.';
					(<any>this).pan.nextElementSibling.style.display = 'block';
					if (!amountError) {
						this.pan.scrollIntoView({ behavior: 'smooth', block: 'center' });
					}
					status = false;
				}
			}

			if (this.address.value == '') {
				this.address.nextElementSibling.innerHTML = 'Please enter your address.';
				(<any>this).address.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.address.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			}
			if (this.pinCode.value == '') {
				this.pinCode.nextElementSibling.innerHTML = 'Please enter your Pin code.';
				(<any>this).pinCode.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.pinCode.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			}
			if (this.state.value == '') {
				this.state.nextElementSibling.innerHTML = 'Please enter your State name.';
				(<any>this).state.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.state.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			}
			if (this.city.value == '') {
				this.city.nextElementSibling.innerHTML = 'Please enter your City name.';
				(<any>this).city.nextElementSibling.style.display = 'block';
				if (!amountError) {
					this.city.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				status = false;
			}
		}
		return status;
	}

	private submitHandler = event => {
		if (event) {
			event.preventDefault();
		}

		let formData = new FormData(event.target);
		let data = {};

		for (let pair of formData.entries()) {
			data[pair[0]] = pair[1];
		}

		let formQueryString = Qs.stringify(data);
		let isMonthly = formData.get('donate-monthly');
		let hasEmail = formData.has('email');
		let actionUrl = event.target.getAttribute('action').substring(1);
		let isMonthlyVal = 0;

		if (isMonthly === 'on') {
			isMonthlyVal = 1;
			actionUrl = event.target.dataset.monthlyAction.substring(1);
		}

		//Build form url without "/" prefix:
		let formUrl = actionUrl + '?' + formQueryString;

		// Form fields validation.
		let validationPass = true;
		// If form has email field it means payment form.
		if (hasEmail) {
			validationPass = this.formFieldsValidate('paymentForm');
			if (validationPass == true && !isNaN(parseFloat(this.otherAmount.value))) {
				this.donateButton.value = 'Processing...';
				this.donateButton.setAttribute('disabled', 'disabled');
				let jsonData = { isMonthly: isMonthlyVal };
				this.makePayment(jsonData);
			}
		} else {
			validationPass = this.formFieldsValidate('donateBlock');
			if (validationPass == true && !isNaN(parseFloat(this.otherAmount.value))) {
				window.Main.templateManager.path(formUrl);
			}
		}
		return false;
	};
}
