import { YearTimelineElement } from './YearTimelineElement';
import { Signal } from '../../../lib/com/hellomonday/signals/Signal';
import { TimelineModule } from '../TimelineModule';

export class MobileSliderYear {
	public onActivate: Signal = new Signal();

	private _element: HTMLElement;

	constructor(private _yearSection: YearTimelineElement, private _timeline: TimelineModule) {
		this.createElement();

		this._element.addEventListener('click', this.click);
	}

	private click = () => {
		this._timeline.tweenToElement(this._yearSection);
	};

	private createElement() {
		this._element = document.createElement('div');

		this._element.classList.add('year');
		this._element.classList.add('rounded-32-light');
		this._element.innerHTML = this._yearSection.getYear().toString();
	}

	public getElement(): HTMLElement {
		return this._element;
	}

	public getYearSection(): YearTimelineElement {
		return this._yearSection;
	}

	public activate() {
		this._element.classList.add('active');

		this.onActivate.dispatch(this);
	}

	public deactivate() {
		this._element.classList.remove('active');
	}
}
