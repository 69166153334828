import { AbstractTemplate } from './AbstractTemplate';
import { TemplateManager } from '../../lib/com/hellomonday/templateManager/TemplateManager';
import * as Qs from 'qs';

declare global {
	//RallyBound vars
	interface Window {
		_rbConf:any
		r_B:any;
	}
}

export class DonationFormTemplate extends AbstractTemplate {

	private iframe:HTMLIFrameElement = null;
	private iframeWrapper:HTMLDivElement = null;
	private introduction:Element;

	constructor(element:HTMLElement, templateManager:TemplateManager) {
		super(element, templateManager);


		this.introduction = element.querySelector('.DonationIntroduction');
		this.iframeWrapper = element.querySelector('#rbEmbedded');

		window._rbConf = window._rbConf || {};
		window._rbConf.account = element.dataset.rallyboundEndpoint;
		window._rbConf.embedType = 'DONATE';
		window._rbConf.isTickets = false;
		window._rbConf.pluginSize = 'RESPONSIVE';
		window._rbConf.additionalOffsetTopForScroll = 70 + ((this.introduction !== null)? this.introduction.clientHeight : 0);
		window._rbConf.otherParams = {
			embed: true,
			standalone: true
		};

		if (window.location.pathname.indexOf('monthly') !== -1) {
			window._rbConf.otherParams.recur = true;
		}

		if (window.location.search) {
			let params = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
			if (params['donate-amount']) {
				window._rbConf.defaultAmount = parseInt(params['donate-amount']); // optional
			}
			if (params['donate-monthly'] && params['donate-monthly'] === 'on') {
				window._rbConf.otherParams.recur = true;
			}
		}

		window._rbConf.onDonationCallback = (donationAmount, donationId, donationRecurringId) => {

			(window as any).dataLayer.push({
				event: "User Action",
				eventCategory: 'User Action',
				eventAction: 'Submitted Donation',
				eventLabel: donationRecurringId ? 'Monthly Donation Submitted' : 'One-Time Donation Submitted',
				eventValue: donationAmount
			});

			window.Main.templateManager.path('donate/thank-you?donationAmount=' + donationAmount);
		};
		window._rbConf.onFirstLoadedCallback = () => {
			this.resize();
			setTimeout(() => this.resize(), 1);
			setTimeout(() => this.resize(), 100);
		}; // optional
		window._rbConf.onPageReadyCallback = () => {
			this.resize();
			setTimeout(() => this.resize(), 1);
			setTimeout(() => this.resize(), 100);
		}; // optional
		window._rbConf.onHeightChanged = (height) => {
			if (this.iframe !== null) {
				this.iframe.style.height = height + 'px';
				//console.log(height);
			}
			// this.resize();
		};

		/*(function() {
			var rb = document.createElement('script');
			rb.type = 'text/javascript';
			rb.async = true;
			rb.src = '//rallybound.com/Content/Framed/plugin.js?' + new Date().getTime();
			var s = document.getElementsByTagName('script')[0];
			s.parentNode.insertBefore(rb, s);
		})();*/
	}

	templateOutComplete() {
		super.templateOutComplete();
		document.body.classList.remove('DonateFormTemplatePage');
	}

	templateIn() {
		super.templateIn();
		document.body.classList.add('DonateFormTemplatePage');
	}

	resize() {
		super.resize();
		if (!this.iframe) {
			this.iframe = this.element.querySelector('iframe');
		}
		if (window.r_B) {
			window.r_B.config.additionalOffsetTopForScroll = 70 + ((this.introduction !== null)? this.introduction.clientHeight : 0);
			if (this.iframe) {
				// this.iframeWrapper.style.height = this.iframe.clientHeight + 'px';
				// this.iframe.style.height = '';
				console.log('refresh height');
				window.r_B.refreshHeight();
				// this.iframeWrapper.style.height = '';
			}
		}
	}

}
