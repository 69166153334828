import { YearTimelineElement } from './YearTimelineElement';
import { YearDot } from './YearDot';
import { Draggable } from '../../../lib/com/greensock/gsap-bonus/Draggable.js';
import { TweenMax } from 'gsap/TweenMax';
import { Dot } from './Dot';
import { SegmentDot } from './SegmentDot';
import { TimelineModule } from '../TimelineModule';
import { Globals } from '../../utils/Globals';

export class TimelineDesktopSlider {
	private _timeline: TimelineModule;
	private _container: HTMLElement;
	private _dotsContainer: HTMLElement;
	private _dots: Dot[] = [];
	private _dragger: HTMLElement;
	private _draggable: Draggable;
	private _snapPoints: number[];

	constructor(element: HTMLElement, timeline: TimelineModule) {
		this._container = element.querySelector('.dots');
		this._timeline = timeline;
		this._dragger = element.querySelector('.dragger-container');
		this._dotsContainer = element.querySelector('.dots');

		this._timeline.onDragged.add(this.updateDraggerPosition, this);

		this._draggable = Draggable.create(this._dragger, {
			type: 'x',
			bounds: this._container,
			throwProps: true,
			throwResistance: 25000,
			edgeResistance: 1,
			snap: [],
			overshootTolerance: 0,
			onDragStart: this.draggerStart,
			onDragStartScope: this,
			onDragEnd: this.draggerRelease,
			onDragEndScope: this,
			onDrag: this.draggerDragged,
			onThrowUpdate: this.draggerDragged,
			onThrowUpdateScope: this,
			onThrowComplete: this.throwComplete,
			onThrowCompleteScope: this,
			onDragScope: this,
			cursor: 'pointer'
		})[0];

		this.createDots();
		this.resize();
	}

	private draggerStart() {
		this._timeline.disableDrag();
	}

	private draggerRelease() {
		this._timeline.enableDrag();
	}

	private throwComplete() {
		this._timeline.scrollEnd();
	}

	private draggerDragged() {
		let percentage = this._draggable.x / this._draggable.maxX;
		if (Globals.RTL) {
			percentage = this._draggable.x / this._draggable.minX;
			// percentage *= -1;
			this.updateDotsState(-percentage);
		} else {
			this.updateDotsState(percentage);
		}
		this._timeline.goToPercentage(percentage);
	}

	private updateDraggerPosition(xPercentagePos: number) {
		/*		if (Globals.RTL) {
					xPercentagePos = xPercentagePos * -1;
				}*/
		console.log('pos gained', xPercentagePos);
		if (!Globals.RTL) {
			if (xPercentagePos > 0) xPercentagePos = 0;
			if (xPercentagePos < -1) xPercentagePos = -1;
		} else {
			// xPercentagePos *= -1;
		}

		let maxX = this._draggable.maxX;
		if (Globals.RTL) {
			maxX = this._draggable.minX * -1;
		}
		TweenMax.set(this._dragger, { x: -maxX * xPercentagePos });
		console.log('pos', xPercentagePos, this._draggable.maxX, this._draggable.minX);

		this.updateDotsState(-xPercentagePos);
	}

	private updateDotsState(xPercentagePos: number) {
		if (Globals.RTL) {
			xPercentagePos = xPercentagePos * -1;
		}
		console.log(xPercentagePos);
		if (xPercentagePos < 0) xPercentagePos = 0;
		if (xPercentagePos > 1) xPercentagePos = 1;

		this._dots.forEach(dot => {
			if (!Globals.RTL) {
				if (dot.getXPos() < xPercentagePos) {
					dot.setPassed();
				} else {
					dot.setNotPassed();
				}
			} else {
				if (dot.getXPos() > 1 - xPercentagePos) {
					dot.setPassed();
				} else {
					dot.setNotPassed();
				}
			}
		});
	}

	private createDots() {
		let lastDots = [];
		this._timeline.getSections().forEach(section => {
			if (section instanceof YearTimelineElement) {
				lastDots.forEach(dot => {
					this._dots.push(dot);
					this._dotsContainer.appendChild(dot.getElement());
				});

				lastDots = [];

				const dot = new YearDot(section, this._timeline);
				this._dots.push(dot);

				this._dotsContainer.appendChild(dot.getElement());
			} else {
				const dot = new SegmentDot(section, this._timeline);

				lastDots.push(dot);
			}
		});
		lastDots.forEach(dot => {
			this._dots.push(dot);
			this._dotsContainer.appendChild(dot.getElement());
		});
	}

	public resize() {
		this._draggable.update(true);
		this._snapPoints = [];

		this._dots.forEach(dot => {
			dot.position(this._container.offsetWidth);

			this._snapPoints.push(dot.getXPos() * this._container.offsetWidth);
		});
		this._snapPoints.push(this._container.offsetWidth);
		/*		console.log(this._snapPoints)

				this._draggable.vars.snap = this._snapPoints;*/
	}
}
