import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { getCookie, setCookie } from '../../lib/com/hellomonday/utils/CookieUtils';
import { randomInteger } from '../../lib/com/hellomonday/utils/MathUtils';
import { TweenMax } from 'gsap/TweenMax';

export class FooterBannerModule extends AbstractModule {
	private activeBannerIndex: number | null = null;
	private banners: NodeListOf<Element>;

	private activeBanner: Element = null;

	private static COOKIE_KEY = 'FooterBannerModule';

	private BANNER_SHOW_TIME = 60;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		let cycleTime = parseInt(element.dataset.bannerCycleSeconds);
		if (cycleTime > 0 && !isNaN(cycleTime)) {
			this.BANNER_SHOW_TIME = cycleTime;
		}

		this.banners = element.querySelectorAll('.FooterBannerModule__banner');
		let previousBannerIndex = getCookie(FooterBannerModule.COOKIE_KEY);
		this.activeBannerIndex = previousBannerIndex !== null ? parseInt(previousBannerIndex) : null;

		if (this.activeBannerIndex > this.banners.length - 1 || this.activeBannerIndex < 0) {
			this.activeBannerIndex = null;
		}

		if (this.activeBannerIndex !== null) {
			this.showNextBanner(true);
		} else {
			this.activeBannerIndex = randomInteger(0, this.banners.length - 1);
			this.showActiveBanner();
		}
	}

	private showNextBanner = (instant = false) => {
		let nextIndex = this.activeBannerIndex + 1;
		if (nextIndex > this.banners.length - 1) {
			nextIndex = 0;
		}
		this.activeBannerIndex = nextIndex;

		this.showActiveBanner();

		TweenMax.delayedCall(this.BANNER_SHOW_TIME, this.showNextBanner);
	};

	private showActiveBanner() {
		if (this.activeBanner) {
			this.activeBanner.classList.remove('FooterBannerModule--active');
		}
		this.activeBanner = this.banners[this.activeBannerIndex];
		this.activeBanner.classList.add('FooterBannerModule--active');
		setCookie(FooterBannerModule.COOKIE_KEY, this.activeBannerIndex + '', 30);
	}

	kill() {
		super.kill();
		TweenMax.killDelayedCallsTo(this.showNextBanner);
	}
}
