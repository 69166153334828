import { TemplateManager } from 'app/lib/com/hellomonday/templateManager/TemplateManager';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { LinkParser } from '../../lib/com/hellomonday/utils/LinkParser';

export class MenuLinkHandler {
	private anchorsMap: { [key: string]: HTMLAnchorElement } = {};
	private activeAnchors: HTMLAnchorElement[] = [];

	public onHashChange: Signal = new Signal();

	constructor(templateManager: TemplateManager, menuElement: HTMLElement) {
		templateManager.signalHashChange.add(this.hashChangeHandler);

		menuElement.querySelectorAll('a').forEach(anchor => {
			const href = anchor.getAttribute('href');

			if (href && href.indexOf('#') !== 0 && href.length > 0) {
				this.anchorsMap[href] = anchor;
			}
		});

		menuElement.querySelectorAll<HTMLAnchorElement>('a.active').forEach(anchor => this.activeAnchors.push(anchor));

		let links = menuElement.querySelectorAll('a');
		LinkParser.getInstance().parseNodeList(links);
	}

	private hashChangeHandler = () => {
		const pathParts = window.location.pathname.split('/');
		let constructedPath = '';

		this.activeAnchors.forEach(anchor => anchor.classList.remove('active'));
		this.activeAnchors = [];

		for (let i = 1; i < pathParts.length; i++) {
			constructedPath += '/' + pathParts[i];
			const anchor = this.anchorsMap[constructedPath];

			if (anchor) {
				anchor.classList.add('active');
				this.activeAnchors.push(anchor);
			}
		}

		this.onHashChange.dispatch();
	};
}
