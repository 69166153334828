import { MenuSection } from './MenuSection';
import { DonateButton } from '../buttons/DonateButton';
import { MenuSizeController } from './MenuSizeController';
import { TweenMax, Power2 } from 'gsap/TweenMax';
import { WindowManager } from '../utils/WindowManager';

export class DonateMenuSection extends MenuSection {
	private _donateButton: DonateButton;
	private _donateMobileButton: HTMLElement;

	private _closeButton: HTMLElement;

	constructor(topLink: HTMLElement, mobileButton: HTMLElement, submenu: HTMLElement, desktopContainer: HTMLElement, donateButton: DonateButton, menuSizeController: MenuSizeController) {
		super(topLink, submenu, desktopContainer, submenu.parentElement, menuSizeController);

		this._donateButton = donateButton;
		this._donateButton.onClick.add(this.donateClicked, this);

		this._donateMobileButton = mobileButton;
		this._donateMobileButton.addEventListener('click', this.donateClicked);
	}

	protected transitionToContractedState() {
		if (WindowManager.width > WindowManager.BREAKPOINT_TABLET) {
			this.getSubMenu().classList.add('hide');
		} else {
			TweenMax.to(this.getSubMenu(), 0.4, { className: '+=hide', ease: Power2.easeInOut });
		}
	}

	protected transitionToExpandedState() {
		if (WindowManager.width > WindowManager.BREAKPOINT_TABLET) {
			this.getSubMenu().classList.remove('hide');
		} else {
			TweenMax.to(this.getSubMenu(), 0.4, { className: '-=hide', ease: Power2.easeInOut });
		}
	}

	protected prepare() {
		this._closeButton = this.getSubMenu().querySelector('.btn-close-donate');
		this._closeButton.addEventListener('click', this.click);
	}

	protected click = (e: MouseEvent) => {
		this.contract();
	};

	private donateClicked = () => {
		this.expand();
	};
}
