import { StatBox } from './StatBox';

export class NumberStatBox extends StatBox {
	private _numberStatElement: HTMLElement;

	constructor(element: HTMLElement) {
		super(element);

		this._numberStatElement = element.querySelector('.stat');
		this.findNumber();
	}

	private findNumber() {
		//just going to leave this here.
	}
}
