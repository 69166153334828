import { WindowManager } from '../utils/WindowManager';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class ScrollController {
	public static signalScroll: Signal = new Signal();
	private isEnabled = true;

	public static viewport = {
		top: 0,
		bottom: 0
	};

	private ticking = false;

	private currRaf: number = 0;

	constructor() {
		window.addEventListener('scroll', this.onScroll);
		this.update();
	}

	private onScroll = () => {
		this.requestUpdate();
	};

	private updateViewport() {
		var scrollY = window.pageYOffset;
		ScrollController.viewport.top = scrollY;
		ScrollController.viewport.bottom = scrollY + WindowManager.height;
	}

	private requestUpdate() {
		if (!this.ticking) {
			this.currRaf = requestAnimationFrame(this.update);
		}

		this.ticking = true;
	}

	private update = (frametime: number = -1) => {
		if (this.isEnabled === true) {
			this.updateViewport();
			ScrollController.signalScroll.dispatch();
		}

		this.ticking = false;
	};

	public resize = () => {
		this.update();
	};

	public kill = () => {
		this.ticking = true;
		cancelAnimationFrame(this.currRaf);
		window.removeEventListener('scroll', this.onScroll);
	};

	public disableScroll = () => {
		this.isEnabled = false;
	};

	public enableScroll = () => {
		this.update();
		this.isEnabled = true;
	};
}
