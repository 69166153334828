import { TweenMax, Power0 } from 'gsap/TweenMax';
import { Signal } from '../../../lib/com/hellomonday/signals/Signal';

export class MapElement {
	protected element: SVGPathElement;
	public onShown: Signal = new Signal();

	private _length: number;
	private _reverse: boolean = false;

	constructor(element: SVGPathElement) {
		this.element = element;

		if (element.classList.contains('reverse')) {
			this._reverse = true;
		}

		if (this._reverse) {
			TweenMax.set(this.element, { drawSVG: '100% 100%' });
		} else {
			TweenMax.set(this.element, { drawSVG: '0% 0%' });
		}

		this._length = element.getTotalLength();
	}

	public getShowDrawEnd(): string {
		return '0% 100%';
	}

	public getAnimationTime(): number {
		return this._length * 0.0012;
	}

	public show(delay: number = 0, timeMultiplier: number = 1) {
		let end = this.getShowDrawEnd();

		TweenMax.to(this.element, this.getAnimationTime() * timeMultiplier, { drawSVG: end, delay: delay, ease: Power0.easeInOut, onComplete: this.fireSignal, onCompleteScope: this });
	}

	private fireSignal() {
		this.onShown.dispatch();
	}

	public hide() {
		TweenMax.to(this.element, 0.2, { drawSVG: '0%' });
	}
}
