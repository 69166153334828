import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { TweenMax } from 'gsap/TweenMax';
import VideoJSWrapper from '../videojs/VideoJSWrapper';

export class SingleMediaBreakerModule extends AbstractModule {
	private refVideo: HTMLVideoElement;
	private refPoster: HTMLDivElement;

	private videojs: any = null;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);
		this.refVideo = element.querySelector('video');
		this.refPoster = element.querySelector('.SingleMediaBreakerModule__poster-wrapper');

		if (this.refVideo) {
			this.refPoster.addEventListener('click', this.posterClickHandler);

			new VideoJSWrapper(this.refVideo, this);
		}
	}

	private posterClickHandler = event => {
		if (event) {
			event.preventDefault();
		}

		if (this.videojs) {
			this.videojs.play();
			TweenMax.to(this.refPoster, 0.2, { autoAlpha: 0 });
		}
	};

	public kill() {
		if (this.videojs) {
			this.refPoster.removeEventListener('click', this.posterClickHandler);
			this.videojs.dispose();
		}

		super.kill();
	}
}
