import { Power1, TweenMax } from 'gsap/TweenMax';
import facon from '../../lib/com/hellomonday/utils/facon';

export class Popup {
	public static POPUP_OPEN = false;
	private bgOverlay: HTMLDivElement = document.createElement('div');

	private refWrapper: HTMLDivElement = null;
	private refImage: HTMLImageElement = null;
	private refDescription: HTMLDivElement = null;
	private refTitle: HTMLHeadingElement = null;
	private refSubheader: HTMLParagraphElement = null;
	private refClose: HTMLDivElement = null;
	private refImageWrapper: HTMLDivElement = null;
	private refDownloadButton: HTMLAnchorElement = null;
	private _className: string | undefined;

	constructor(className: string | undefined) {
		this._className = className;
		this.setupPopup();
	}

	private setupPopup() {
		// language=HTML
		const node = facon(`
			<div ref="refWrapper" class="Popup ${this._className ? this._className : ''}">
				<div class="Popup__inner">
					<div class="Popup__controls">
						<div ref="refClose" class="Popup__control Popup__control--close">
							<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
								<g transform="translate(-14 -14)" fill="#FFF" fill-rule="evenodd">
									<rect transform="rotate(45 20 20)" x="12" y="19" width="16" height="2" rx="1"/>
									<rect transform="rotate(135 20 20)" x="12" y="19" width="16" height="2" rx="1"/>
								</g>
							</svg>
						</div>
					</div>
					<div ref="refImageWrapper" class="Popup__image-wrapper">
						<img class="lazyload" ref="refImage"/>
					</div>
					<div class="Popup__right">
						<h3 ref="refTitle" class="rounded-32-light Popup__title"></h3>
						<p ref="refSubheader" class="rounded-16-light Popup__subheader"></p>
						<div ref="refDescription" class="source-16-regular Popup__description"></div>
						<a ref="refDownloadButton" download class="source-14-regular btn-pill filled Popup__download-button">
							<span class="source-16-semibold">Download Bio</span>
							<svg width="14" height="18" xmlns="http://www.w3.org/2000/svg">
								<g fill="none" fill-rule="evenodd">
									<path stroke="#FFF" stroke-width="1.66" stroke-linecap="round" stroke-linejoin="round" d="M11.9 7.36L6.46 12.5 1 7.36"/>
									<path d="M7.23.78v11.69a.78.78 0 1 1-1.56 0V.77a.78.78 0 1 1 1.56 0z" fill="#FFF"/>
									<path d="M1 16.56h11.69" stroke="#FFF" stroke-width="1.66" stroke-linecap="round"/>
								</g>
							</svg>
						</a>
					</div>
				</div>
			</div>`);
		node.collect({ to: this });

		this.bgOverlay.classList.add('Popup__bg-overlay');
		TweenMax.set(this.bgOverlay, { opacity: 0 });
		TweenMax.set(this.refWrapper, { opacity: 0, y: 50 });

		this.bgOverlay.addEventListener('click', this.hide);
		this.refClose.addEventListener('click', this.hide);
	}

	public show = (title: string, subheader: string, description: string, imageSrcset: string, downloadLink: string | null, offsetTop: number) => {
		if (Popup.POPUP_OPEN) return;
		Popup.POPUP_OPEN = true;
		this.refDescription.innerHTML = description;
		this.refTitle.innerHTML = title;
		this.refSubheader.innerHTML = subheader;
		if (imageSrcset && imageSrcset !== '') {
			this.refWrapper.classList.remove('no-image');
			this.refImage.dataset.srcset = imageSrcset;
			this.refImage.dataset.src = imageSrcset.split(',')[0];
			this.refImage.setAttribute('class', 'lazyload');
		} else {
			this.refImage.dataset.srcset = '';
			this.refImage.dataset.src = '';
			this.refImage.src = '';
			this.refImage.srcset = '';
			this.refWrapper.classList.add('no-image');
		}

		if (!downloadLink) {
			this.refDownloadButton.classList.add('hidden');
		} else {
			this.refDownloadButton.href = downloadLink;
			this.refDownloadButton.classList.remove('hidden');
		}

		document.body.appendChild(this.bgOverlay);
		document.body.appendChild(this.refWrapper);
		TweenMax.set(this.refWrapper, { top: offsetTop });
		TweenMax.to(this.bgOverlay, 0.3, { opacity: 0.9 });
		TweenMax.to(this.refWrapper, 0.5, { opacity: 1, y: 0, ease: Power1.easeOut });
		document.documentElement.addEventListener('keyup', this.keyUpHandler);
	};

	private keyUpHandler = event => {
		if (event.key === 'Escape') {
			this.hide();
		}
	};

	public hide = () => {
		document.documentElement.removeEventListener('keyup', this.keyUpHandler);
		Popup.POPUP_OPEN = false;
		TweenMax.to(this.bgOverlay, 0.3, {
			opacity: 0,
			onComplete: () => {
				document.body.removeChild(this.bgOverlay);
			}
		});
		TweenMax.to(this.refWrapper, 0.3, {
			opacity: 0,
			y: 50,
			ease: Power1.easeOut,
			onComplete: () => {
				document.body.removeChild(this.refWrapper);
			}
		});
	};

	public kill() {
		Popup.POPUP_OPEN = false;
		document.documentElement.removeEventListener('keyup', this.keyUpHandler);
		if (this.bgOverlay.parentNode) {
			this.bgOverlay.parentNode.removeChild(this.bgOverlay);
		}
		if (this.refWrapper.parentNode) {
			this.refWrapper.parentNode.removeChild(this.refWrapper);
		}
	}
}
