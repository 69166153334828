import { AbstractModule } from '../../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../../templates/AbstractTemplate';
import { Draggable } from '../../../lib/com/greensock/gsap-bonus/Draggable.js';
import { TweenMax, Power2 } from 'gsap/TweenMax';
import { Signal } from '../../../../app/lib/com/hellomonday/signals/Signal';
import { Globals } from '../../utils/Globals';

export abstract class Carousel extends AbstractModule {
	public onSnapChanged: Signal = new Signal();

	protected carouselContainer: HTMLElement;
	protected draggedArea: HTMLElement;

	protected draggable: Draggable;
	private _snapPoints: number[] = [];

	private _currentSnapPointIndex: number = -1;

	constructor(draggedArea: HTMLElement, carouselContainer: HTMLElement, element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this.carouselContainer = carouselContainer;
		this.draggedArea = draggedArea;

		this.draggable = Draggable.create(this.draggedArea, {
			type: 'x',
			throwProps: true,
			overshootTolerance: 0.2,
			throwResistance: 2000,
			maxDuration: 1,
			edgeResistance: 0.9,
			dragClickables: true,
			zIndexBoost: false,
			trigger: element,
			onThrowUpdate: this.dragged,
			onThrowUpdateScope: this,
			onDrag: this.carouselDragged,
			onDragScope: this,
			onThrowComplete: this.scrollEnd,
			onThrowCompleteScope: this,
			cursor: 'default'
		})[0];

		this.prepare();
		this.resize();
	}

	protected prepare() {
		this.draggable.vars.bounds = this.getBounds();
		this.draggable.update(true);
	}

	protected getBounds() {
		let bounds = Globals.RTL ? { minX: 0, maxX: -this.getWidth() + this.getScrollAreaWidth() - 10 } : { minX: 10, maxX: -this.getWidth() + this.getScrollAreaWidth() - 10 };
		return bounds;
	}

	protected abstract getWidth(): number;

	protected getScrollAreaWidth(): number {
		return this.carouselContainer.offsetWidth;
	}

	public previous() {
		this.tweenToSnapIndex(this._currentSnapPointIndex - 1);
	}

	public next() {
		this.tweenToSnapIndex(this._currentSnapPointIndex + 1);
	}

	protected tweenToSnapIndex(index: number, animate: boolean = true) {
		if (this._snapPoints.length == 0) {
			return;
			// console.error('no values found in array');
		}

		if (index > this._snapPoints.length - 1) {
			index = this._snapPoints.length - 1;
		} else if (index < 0) {
			index = 0;
		}

		if (animate) {
			const animTime = 0.6;

			TweenMax.to(this.draggable, animTime, { x: this._snapPoints[index], ease: Power2.easeOut, onUpdate: this.dragged, onUpdateScope: this });
			TweenMax.to(this.draggedArea, animTime, { x: this._snapPoints[index], ease: Power2.easeOut });
		} else {
			TweenMax.set(this.draggable, { x: this._snapPoints[index] });
			TweenMax.set(this.draggedArea, { x: this._snapPoints[index] });
			this.setSnapPoint(index);
		}
	}

	protected getCurrentScrollX(): number {
		let x: number = this.draggable.x;

		if (x.toString() == 'undefined') {
			//this is not ideal. for some reason i have to convert to string to check for undefined.
			return 0;
		}

		return parseInt(this.draggable.x.toString());
	}

	private carouselDragged() {
		this.dragged();
	}

	protected dragged() {
		this.updateCurrentSnapPoint();
	}

	public scrollEnd() {
		this.updateCurrentSnapPoint();
	}

	public disableDrag() {
		this.draggable.disable();
	}

	public enableDrag() {
		this.draggable.enable();
	}

	private updateCurrentSnapPoint() {
		for (let i = 0; i < this._snapPoints.length; i++) {
			if ((!Globals.RTL && this._snapPoints[i] <= this.draggable.x - 1) || (Globals.RTL && this._snapPoints[i] >= this.draggable.x - 1)) {
				let belowDistance = Infinity;
				let aboveDistance = Infinity;

				if (i != 0) {
					belowDistance = Math.abs(this._snapPoints[i - 1] - this.draggable.x);
				}

				aboveDistance = Math.abs(this._snapPoints[i] - this.draggable.x);

				if (belowDistance < aboveDistance) {
					this.setSnapPoint(i - 1);
				} else {
					this.setSnapPoint(i);
				}

				break;
			}
		}
	}

	private setSnapPoint(index) {
		if (index == this._currentSnapPointIndex) {
			return;
		}

		this._currentSnapPointIndex = index;
		this.onSnapChanged.dispatch(this._currentSnapPointIndex);
	}

	protected abstract getSnapPoints(): number[];

	protected updateSnapPoints() {
		this._snapPoints = this.getSnapPoints();
		this.draggable.vars.snap = this._snapPoints;
	}

	public getMaxSnapPointIndex() {
		return this._snapPoints.length - 1;
	}

	public resize() {
		this.updateSnapPoints();
		this.tweenToSnapIndex(this._currentSnapPointIndex, false);

		this.draggable.vars.bounds = this.getBounds();
		this.draggable.update(true);

		super.resize();
	}

	protected getCurrentSnapPointIndex(): number {
		return this._currentSnapPointIndex;
	}
}
