import { AbstractTemplate } from './AbstractTemplate';
import { TemplateManager } from '../../lib/com/hellomonday/templateManager/TemplateManager';
import { PressSearch } from '../search/PressSearch';

export class PressReleasesOverviewTemplate extends AbstractTemplate {
	private search:PressSearch;

	constructor(element:HTMLElement, templateManager:TemplateManager) {
		super(element, templateManager);

		this.search = new PressSearch(
			element.querySelector('.PressReleasesSearchInputModule__input-wrapper > input'),
			element.querySelector('.PressSearch__result_list'),
			element.querySelector('.PressSearch__result_list__append'),
			element.querySelector('.PressSearch__hide-list'),
			element.querySelector('.PressReleasesSearchInputModule select[name="year"]'),
			element.querySelector('.PressReleasesSearchInputModule select[name="country"]')

		);
	}

}
