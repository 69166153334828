import analytics from './VideoJSAnalytics';

class VideoJSWrapper {
	constructor(video: HTMLVideoElement, assignTo: any) {
		import(/* webpackChunkName: "videojs", webpackPrefetch: true */ 'video.js').then(({ default: videojs }) => {
			(window as any).videojs = videojs;
			// Register the plugin with video.js.
			videojs.plugin('analytics', analytics);
			let player = videojs(video, {
				html5: {
					nativeControlsForTouch: true
				}
			});

			let source = video.querySelector('source');
			let sourceString = `no-name: (page:${window.location.pathname})`;
			if (source) {
				sourceString = source.src;
			} else if (video.src) {
				sourceString = video.src;
			}

			let analyticsInstance = player.analytics({
				mode: 'GA',
				assetName: sourceString,
				events: [
					{
						name: 'play',
						label: 'video play',
						action: 'Play'
					},
					{
						name: 'pause',
						label: 'video pause',
						action: 'Pause'
					},
					{
						name: 'ended',
						label: 'video ended',
						action: 'End'
					},
					{
						name: 'error',
						label: 'error',
						action: 'Error'
					},
					{
						name: 'timeupdate',
						action: 'time updated'
					}
				]
			});

			assignTo.videojs = player;
		});
	}
}

export default VideoJSWrapper;
