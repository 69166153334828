import { YearTimelineElement } from './YearTimelineElement';
import { MobileSliderYear } from './MobileSliderYear';
import { TweenMax } from 'gsap/TweenMax';
import { WindowManager } from '../../utils/WindowManager';
import { TimelineModule } from '../TimelineModule';

export class TimelineMobileSlider {
	private _yearSections: YearTimelineElement[] = [];
	private _yearSliderElements: MobileSliderYear[] = [];
	private _yearContainer: HTMLElement;
	private _yearSectionCount: number;

	private _shown: boolean = false;
	private _currentYear: YearTimelineElement;
	private _currentSliderYear: MobileSliderYear;

	constructor(private _element: HTMLElement, private _timeline: TimelineModule) {
		this._yearContainer = this._element.querySelector('.years');

		this.findYearSections();
		this.createSections();

		this._yearSectionCount = this._yearSections.length;
		this._currentSliderYear = this._yearSliderElements[0];

		this._timeline.onMoved.add(this.onTimelineMoved, this);
	}

	private onTimelineMoved(newX: number) {
		const xPos = -this._timeline.getPosFromPercentage(newX);

		for (let i = 0; i < this._yearSectionCount; i++) {
			if (this._yearSections[i].getXPos() > xPos + 100) {
				let numToGoTo = i - 1;
				if (numToGoTo < 0) numToGoTo = 0;

				this.goToYear(this._yearSections[numToGoTo]);
				return;
			}
		}

		this.goToYear(this._yearSections[this._yearSectionCount - 1]);
	}

	public goToYear(element: YearTimelineElement) {
		if (this._currentYear == element) {
			return;
		}

		this._yearSliderElements.forEach(sliderElement => {
			if (element.getYear() == sliderElement.getYearSection().getYear()) {
				sliderElement.activate();
				this._currentSliderYear = sliderElement;
			} else {
				sliderElement.deactivate();
			}
		});

		this._currentYear = element;
	}

	private findYearSections() {
		const sections = this._timeline.getSections();

		sections.forEach(section => {
			if (section instanceof YearTimelineElement) {
				this._yearSections.push(section);
			}
		});
	}

	private createSections() {
		this._yearSections.forEach(section => {
			this.createSection(section);
		});

		this._currentYear = this._yearSections[0];
		this._yearSliderElements[0].activate();
	}

	private scrollToSliderYear(sliderYear: MobileSliderYear) {
		TweenMax.to(this._yearContainer, 0.2, { x: -sliderYear.getElement().offsetLeft });
	}

	private createSection(section: YearTimelineElement) {
		let sliderYearPart = new MobileSliderYear(section, this._timeline);
		this._yearContainer.appendChild(sliderYearPart.getElement());
		this._yearSliderElements.push(sliderYearPart);

		sliderYearPart.onActivate.add(this.scrollToSliderYear, this);
	}

	public resize() {
		if (this._shown && WindowManager.width >= WindowManager.BREAKPOINT_TABLET) {
			console.log('hidden');
			this._shown = false;
		} else if (!this._shown && WindowManager.width < WindowManager.BREAKPOINT_TABLET) {
			console.log('shown');
			this.scrollToSliderYear(this._currentSliderYear);
			this._shown = true;
		}
	}
}
