import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { Popup } from './Popup';
import { getOffsetTopForElement } from '../../lib/com/hellomonday/utils/DomUtils';
import { WindowManager } from '../utils/WindowManager';

export class PopupModule extends AbstractModule {
	private listElements: NodeListOf<Element>;
	private popup: Popup;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this.popup = new Popup(this.element.dataset.popupClass);

		this.listElements = this.element.querySelectorAll('.ImageGridItem a');
		this.listElements.forEach(element => {
			element.addEventListener('click', this.clickedListElement);
		});

		this.checkHash();
	}

	private clickedListElement = event => {
		if (event) {
			event.preventDefault();
		}
		let gridItem = event.currentTarget.parentNode as HTMLElement;
		this.showPopupForElement(gridItem);
	};

	private showPopupForElement(gridItem: HTMLElement) {
		let offsetTop = Math.round(getOffsetTopForElement(gridItem) - gridItem.getBoundingClientRect().top);
		if (WindowManager.width >= WindowManager.BREAKPOINT_TABLET_PORTRAIT) {
			offsetTop += 100;
		}

		let subHeader = gridItem.querySelector('.content > p');
		this.popup.show(
			gridItem.querySelector('.content > h5').innerHTML,
			subHeader ? subHeader.innerHTML : '',
			gridItem.dataset.description,
			gridItem.dataset.image,
			gridItem.dataset.download,
			offsetTop
		);
	}

	kill() {
		super.kill();
		this.popup.kill();
		this.popup = null;
	}

	private checkHash() {
		let hash = window.location.hash;
		if (hash != '' && hash.length > 1) {
			let target = null;
			for (let i = 0; i < this.listElements.length; i++) {
				const listElement = this.listElements[i];
				if (listElement.getAttribute('href') === hash) {
					target = listElement;
					break;
				}
			}
			if (target) {
				this.showPopupForElement(target.parentNode);
			}
		}
	}
}
