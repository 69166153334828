import { MenuButton } from '../buttons/MenuButton';
import { MainMenuSection } from './MainMenuSection';
import { WindowManager } from '../utils/WindowManager';
import { MenuSizeController } from './MenuSizeController';
import { MenuSection } from './MenuSection';
import { DonateMenuSection } from './DonateMenuSection';
import { DonateButton } from '../buttons/DonateButton';
import { TemplateManager } from 'app/lib/com/hellomonday/templateManager/TemplateManager';
import { TweenMax, Power2, Power1 } from 'gsap/TweenMax';
import { MenuScrollVisibilityHandler } from './MenuScrollVisibilityHandler';
import { MenuLinkHandler } from './MenuLinkHandler';
import { Search } from '../search/Search';

export class Menu {
	private _element: HTMLElement;

	private _menuButton: MenuButton;
	private _search: Search;
	private _menuContent: HTMLElement;
	private _mobileContactMenu: HTMLElement;
	private _logo: HTMLElement;
	private _mainMenu: HTMLElement;

	// private _donateButton: DonateButton;

	private _menuSizeController: MenuSizeController;
	private _menuLinkHandler: MenuLinkHandler;
	private _menuScrollVisibilityHandler: MenuScrollVisibilityHandler;

	private _isExpanded: boolean;
	private _inMobileState: boolean = true;
	private _menuSections: MenuSection[] = [];
	private _donateMenuSection: DonateMenuSection;

	private _blackout: HTMLElement;

	constructor(element: HTMLElement, templateManager: TemplateManager) {
		this._element = element;
		this._logo = element.querySelector('.logo');

		this.prepareVariables();
		this.addLinks(templateManager);
		this.setupMenuSections();

		this.onResize();
		this.moveMenu();

		WindowManager.signalResize.add(this.onResize, this);
	}

	private prepareVariables() {
		this._menuSizeController = new MenuSizeController(this._element);
		this._menuSizeController.onContract.add(this.contract, this);

		this._menuScrollVisibilityHandler = new MenuScrollVisibilityHandler(this._element.querySelector('.menu-wrapper'), this);
		this._menuButton = new MenuButton(this._element.querySelector('.btn-burger'));
		this._menuButton.onClick.add(this.toggleMenu, this);
		this._menuContent = this._element.querySelector('.menu-content');
		this._mainMenu = this._element.querySelector('.main-menu');

		this._mobileContactMenu = this._element.querySelector('.mobile-contact-points');
		this._blackout = this._element.querySelector('.blackout');

		this._search = new Search(this._element.querySelector('.Search'), this._element.querySelector('.search-icon'), this);
		this.changeState();
	}

	private setupMenuSections() {
		//setup the main four menu sections
		const menuSections = this._element.querySelectorAll('.menu-section');
		const desktopContainer: HTMLElement = this._element.querySelector('.desktop-submenu-container');
		const desktopDonateContainer: HTMLElement = this._element.querySelector('.desktop-donate-submenu-container');

		//setup the main menu items
		menuSections.forEach(sectionElement => {
			const topLink: HTMLElement = sectionElement.querySelector('.top-link');
			const section = new MainMenuSection(topLink, sectionElement.querySelector('.sub-menu'), desktopContainer, sectionElement as HTMLElement, this._menuSizeController);

			this._menuSections.push(section);
			section.expandSignal.add(this.contractAllOtherSubMenus, this);
			section.toggleSignal.add(this.moveMobileMenuToSection, this);
			section.signalKeyboardContract.add(this.contract, this);
		});

		//setup the donate section
		const donateMenu = new DonateMenuSection(
			this._element.querySelector('.donate-top-link'),
			this._element.querySelector('.donate-mobile-top-link'),
			this._element.querySelector('.donate-sub-menu'),
			desktopDonateContainer,
			new DonateButton(this._element.querySelector('.donate-top-link')),
			this._menuSizeController
		);

		this._donateMenuSection = donateMenu;

		this._menuSections.push(donateMenu);
		donateMenu.expandSignal.add(this.contractAllOtherSubMenus, this);
		donateMenu.signalKeyboardContract.add(this.contract, this);
	}

	private addLinks(templateManager: TemplateManager) {
		//add the links to the templating system
		this._menuLinkHandler = new MenuLinkHandler(templateManager, this._element);
		this._menuLinkHandler.onHashChange.add(this.onLinkChanged, this);
	}

	private onLinkChanged() {
		this.contract();
	}

	private toggleMenu() {
		if (this._isExpanded) {
			this.contract();
		} else {
			this.expand();
		}
	}

	private expand(menuSection?: MenuSection) {
		if (menuSection == null) {
			this._menuSizeController.expand('100vh', 0, false);
		} else {
			this._menuSizeController.resizeToMenuSection(menuSection);
			this._menuSizeController.listenForExit();
		}

		if (this._inMobileState) {
			this._menuContent.classList.add('fixed-header');
		}

		this._isExpanded = true;
		this._menuButton.showClose(false);
		this._element.classList.add('expanded');
		// Expand the sub-menu part.
		TweenMax.to(this._blackout, MenuSizeController.ANIMATION_TIME, { className: '+=visible', ease: Power2.easeInOut });
		this._element.classList.add('expanded');
		TweenMax.delayedCall(0.4, this.showContactLinks, null, this);
		// If no sub-menu found contract the sub-menu overlay.
		if (menuSection !== undefined && menuSection._submenu == null) {
			this.contract();
		}
	}

	private showContactLinks() {
		this._mobileContactMenu.classList.add('visible');
	}

	public contract(instant: boolean = false) {
		this._menuButton.showBurger(instant);
		this._menuSizeController.contract(this._inMobileState, instant);

		this._isExpanded = false;
		this._mobileContactMenu.classList.remove('visible');

		this._menuContent.classList.remove('fixed-header');

		TweenMax.killDelayedCallsTo(this.showContactLinks);
		TweenMax.to(this._blackout, MenuSizeController.ANIMATION_TIME, { className: '-=visible', ease: Power2.easeInOut, onComplete: this.menuContractAnimationComplete, onCompleteScope: this });
	}

	private menuContractAnimationComplete() {
		this._element.classList.remove('expanded');
	}

	private changeState() {
		if (this._inMobileState) {
			this._menuSections.forEach(sectionElement => {
				sectionElement.setMobileMode();
				sectionElement.hoverSignal.remove(this.menuItemHovered, this);
			});

			this._menuSizeController.stopListeningForExit();
		} else {
			this._menuSections.forEach(sectionElement => {
				sectionElement.setDesktopMode();
				sectionElement.hoverSignal.add(this.menuItemHovered, this);
			});
		}

		this.contract(true);
	}

	private menuItemHovered(menuSection: MainMenuSection) {
		this.contractAllOtherSubMenus(menuSection);

		menuSection.expand();

		this.expand(menuSection);
	}

	private contractAllOtherSubMenus(menuSection: MenuSection) {
		this._menuSections.forEach(loopSection => {
			if (loopSection !== menuSection) {
				loopSection.contract();
			}
		});

		this.moveMobileMenuToSection(menuSection);
	}

	private moveMenu() {
		if (WindowManager.isMobile()) {
			this._menuContent.appendChild(this._logo);
		} else {
			this._mainMenu.insertBefore(this._logo, this._mainMenu.firstChild);
		}
	}

	private moveMobileMenuToSection(menuSection: MenuSection) {
		/*		if (this._draggable != null) {
			TweenMax.to(this._draggable, 0.4, { y: 0, ease: Power1.easeInOut });
		}*/

		TweenMax.to(this._mainMenu, 0.4, { scrollTo: { y: menuSection.getContainer(), offsetY: 60 }, delay: 0.4, ease: Power1.easeInOut });
	}

	private onResize() {
		const currentState = this._inMobileState;

		if (WindowManager.isMobile()) {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			this._inMobileState = true;
			this._donateMenuSection.contract();
		} else {
			this._inMobileState = false;
		}

		if (currentState !== this._inMobileState) {
			this.moveMenu();
			this.changeState();
		}
	}

	public isExpanded(): boolean {
		return this._isExpanded;
	}

	public keepVisible() {
		this._menuScrollVisibilityHandler.deactivate();
	}

	public setSearchVisible() {
		this._menuContent.classList.add('fixed-header');
	}

	public hideOnScroll(checkScrollPosition = true) {
		this._menuScrollVisibilityHandler.activate(checkScrollPosition);
	}
}
