import { AbstractTemplate } from '../templates/AbstractTemplate';
import { Wave } from './map/Wave';
import { IntersectionObserverModule } from '../general/IntersectionObserverModule';
import { TweenMax } from 'gsap/TweenMax';

export class MapModule extends IntersectionObserverModule {
	private _map: SVGElement;
	private _waves: Wave[] = [];
	private sidebarLinks: NodeListOf<Element>;
	private mapRegions: NodeListOf<Element>;

	private hoverIndex = -1;

	public constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._map = element.querySelector('.mapContainer > svg');
		this.observe(this._map);

		this.sidebarLinks = this.element.querySelectorAll('.sidebar > a');
		this.mapRegions = this.element.querySelectorAll('#svgHovers > g');

		this.organizeSVG();
		this._map.style.visibility = 'visible';
		this.setupHovers();
	}

	protected elementIntersectFound(element: Element) {
		this.show();
	}

	protected getOptions(): IntersectionObserverInit {
		return { threshold: [0.3] };
	}

	private show() {
		this._waves.forEach(wave => {
			wave.show(Math.random() * 1, 4);
		});
	}

	private organizeSVG() {
		let waves = this._map.querySelectorAll('#svgWaves > *');

		waves.forEach(wave => {
			this._waves.push(new Wave(wave as SVGPathElement));
		});
	}

	public kill() {
		super.kill();
	}

	private setupHovers() {
		this.sidebarLinks.forEach((element, index) => {
			const indexSaved = index;
			element.addEventListener('mouseover', () => {
				this.hoverOver(indexSaved);
			});
			element.addEventListener('mouseout', () => {
				this.hoverOver(-1);
			});
		});
		this.mapRegions.forEach((element, index) => {
			const indexSaved = index;
			element.addEventListener('mouseover', () => {
				this.hoverOver(indexSaved);
			});
			element.addEventListener('mouseout', () => {
				this.hoverOver(-1);
			});
			/*element.addEventListener('click', () => {
				window.Main.templateManager.path(this.sidebarLinks[indexSaved].getAttribute('href').replace('/', ''));
			});*/
		});
	}

	private hoverOver = (index: number) => {
		if (index === this.hoverIndex) {
			return;
		}
		if (this.hoverIndex != -1) {
			this.sidebarLinks[this.hoverIndex].classList.remove('active');
			this.mapRegions[this.hoverIndex].classList.remove('active');
		}
		this.hoverIndex = index;
		if (this.hoverIndex != -1) {
			this.sidebarLinks[this.hoverIndex].classList.add('active');
			this.mapRegions[this.hoverIndex].classList.add('active');
		}
	};
}
