import { TweenMax, Power2, Tween } from 'gsap/TweenMax';
import { MenuSection } from './MenuSection';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class MenuSizeController {
	public static ANIMATION_TIME: number = 0.5;

	private _menuWrapperElement: HTMLElement;
	private _currentMenuSection: MenuSection;

	public onContract: Signal = new Signal();
	public onContracted: Signal = new Signal();
	public onSizeChange: Signal = new Signal();

	private _currentTargetHeight: string = '0';

	constructor(element: HTMLElement) {
		this._menuWrapperElement = element.querySelector('.menu-wrapper');
	}

	public resizeToMenuSection(menuSection: MenuSection) {
		if (this._currentMenuSection == menuSection) return;

		this.updateSize(menuSection.getSubmenuHeight() + 80 + 'px');
		this._currentMenuSection = menuSection;
	}

	public contract(toMobileMenu: boolean, instant: boolean = false) {
		if (toMobileMenu) {
			this.updateSize('60px', 0, instant);
		} else {
			this.updateSize('70px', 0, instant);
		}

		TweenMax.delayedCall(MenuSizeController.ANIMATION_TIME, this.dispatchContracted, null, this);

		this._currentMenuSection = null;
	}

	private dispatchContracted() {
		this.onContracted.dispatch();
	}

	public expand(to: string, delay: number = 0, instant: boolean = false) {
		this.updateSize(to, delay, instant);

		this._currentMenuSection = null;
	}

	private updateSize(to: string, delay: number = 0, instant: boolean = false) {
		if (to == this._currentTargetHeight) {
			return;
		}

		TweenMax.killDelayedCallsTo(this.dispatchContracted);
		TweenMax.killTweensOf(this._menuWrapperElement);

		this._currentTargetHeight = to;

		if (instant) {
			TweenMax.set(this._menuWrapperElement, { height: to });
			this.fireSizeChangeSignal();

			return;
		}

		TweenMax.to(this._menuWrapperElement, MenuSizeController.ANIMATION_TIME, {
			height: to,
			delay: delay,
			onUpdate: this.fireSizeChangeSignal,
			onUpdateScope: this,
			onComplete: this.fireSizeChangeSignal,
			onCompleteScope: this,
			ease: Power2.easeInOut
		});
	}

	private fireSizeChangeSignal() {
		this.onSizeChange.dispatch();
	}

	public listenForExit() {
		this._menuWrapperElement.addEventListener('mouseleave', this.exitedContainer);
	}

	public stopListeningForExit() {
		this._menuWrapperElement.removeEventListener('mouseleave', this.exitedContainer);
	}

	private exitedContainer = (e: MouseEvent) => {
		this.onContract.dispatch();
	};
}
