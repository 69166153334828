import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';

export class JobListingModule extends AbstractModule {

  private allAccordions: NodeListOf<HTMLElement>;

  constructor(element: HTMLElement, template: AbstractTemplate) {
    super(element, template);
    this.allAccordions = element.querySelectorAll("div.accordion");

    // All elements with class '.accordion'.
    element.querySelectorAll('.accordion').forEach(accordion => {
      accordion.addEventListener('click', event => {
        accordion.classList.toggle('active');
        accordion.nextElementSibling.classList.toggle('show');
        accordion.nextElementSibling.scrollTop = 0;
        accordion.scrollIntoView(true);
        accordion.scrollIntoView({behavior: "smooth"});
        for (let i = 0; i < this.allAccordions.length; i++) {
          if (this.allAccordions[i] !== accordion) {
            this.allAccordions[i].classList.remove("active");
            this.allAccordions[i].nextElementSibling.classList.remove("show");
          }
        }
      })
    })
  }
}
