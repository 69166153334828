import { AbstractTemplate } from './AbstractTemplate';
import { TemplateManager } from '../../lib/com/hellomonday/templateManager/TemplateManager';
import * as Qs from 'qs';

export class DonationThankYouTemplate extends AbstractTemplate {

	private subHeader:HTMLElement;

	constructor(element:HTMLElement, templateManager:TemplateManager) {
		super(element, templateManager);

		this.subHeader = element.querySelector('.HeroModule__custom__text__subheader');

		if (window.location.search) {
			let params = Qs.parse(window.location.search, { ignoreQueryPrefix: true });
			if (params['donationAmount']) {

				this.subHeader.innerHTML = this.subHeader.innerHTML.replace('{donationAmount}', parseInt(params['donationAmount']) + '$');
				this.subHeader.style.opacity = '1';
			}
		}
	}

}
