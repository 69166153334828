import { TweenMax, Power1, TimelineMax, Linear } from 'gsap/TweenMax';
import { MenuSection } from './MenuSection';

export class MainMenuSection extends MenuSection {
	private _plusSvg: SVGElement;
	private _mobileExpandToggle: HTMLElement;
	private _timeline: TimelineMax;

	protected prepare() {
		this._mobileExpandToggle = this.mobileContainer.querySelector('.mobile-expand-toggle');
		if (this._mobileExpandToggle !== null) {
			this._mobileExpandToggle.addEventListener('click', this.click);

			this._plusSvg = this._mobileExpandToggle.querySelector('svg');
		}

		this._timeline = new TimelineMax({
			paused: true,
			ease: Linear.easeNone
		});
		if (this._plusSvg !== undefined) {
			this._timeline.to(this._plusSvg.querySelector('#hoz-line'), this.animationTime * 0.75, { rotation: 90, transformOrigin: 'center center', ease: Linear.easeNone }, 0);
			this._timeline.to(this._plusSvg.querySelector('#plus'), this.animationTime, { rotation: 90, transformOrigin: 'center center', ease: Linear.easeNone }, 0);
		}
	}

	protected doContractAnimation() {
		super.doContractAnimation();

		TweenMax.to(this._timeline, this.animationTime, { progress: 0, ease: Power1.easeOut });

		// TweenMax.to(this._plusSvg.querySelector('#hoz-line'), this.animationTime * 0.75, { rotation: 0, transformOrigin: 'center center', ease: Power1.easeOut });
		// TweenMax.to(this._plusSvg.querySelector('#plus'), this.animationTime, { rotation: 0, transformOrigin: 'center center', ease: Power1.easeOut });
	}

	protected doExpandAnimation() {
		super.doExpandAnimation();

		TweenMax.to(this._timeline, this.animationTime, { progress: 1, ease: Power1.easeOut });

		// TweenMax.to(this._plusSvg.querySelector('#hoz-line'), this.animationTime * 0.75, { rotation: 90, transformOrigin: 'center center', ease: Power1.easeOut });
		// TweenMax.to(this._plusSvg.querySelector('#plus'), this.animationTime, { rotation: 90, transformOrigin: 'center center', ease: Power1.easeOut });
	}
}
