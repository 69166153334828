import { SwiftypeAPI, SwiftypeAPIResponse } from './SwiftypeAPI';
import { TweenMax } from 'gsap/TweenMax';
import { debounce } from 'throttle-debounce';
import { Globals } from '../utils/Globals';

export class PressSearch {
	private swiftypeAPI: SwiftypeAPI;

	private _inputElement: HTMLInputElement;

	private _spellingSuggestion: Element = document.createElement('a');

	private hasResults = false;
	private _currentSpellingSuggestion: string = '';
	private _resultContainer: HTMLElement;
	private _resultContainerList: HTMLElement;
	private _hideItem: HTMLElement;
	private _yearSelector: HTMLSelectElement;
	private _countrySelector: HTMLSelectElement;

	constructor(
		inputElement: HTMLInputElement,
		resultContainer: HTMLElement,
		resultContainerList: HTMLElement,
		hideItem: HTMLElement,
		yearSelector: HTMLSelectElement,
		countrySelector: HTMLSelectElement
	) {
		this._inputElement = inputElement;
		this._resultContainer = resultContainer;
		this._resultContainerList = resultContainerList;
		this._hideItem = hideItem;
		this._yearSelector = yearSelector;
		this._countrySelector = countrySelector;
		this._spellingSuggestion.classList.add('PressSearch__header');
		this._spellingSuggestion.classList.add('Search__search-suggestion');

		this.swiftypeAPI = new SwiftypeAPI('press_release');

		this._inputElement.addEventListener('input', this.inputChangeHandler);
		this._yearSelector.addEventListener('change', this.inputChangeHandler);
		this._countrySelector.addEventListener('change', this.inputChangeHandler);

		this._spellingSuggestion.addEventListener('click', this.setSpellingSuggestion);

		if (Globals.IS_TOUCH_DEVICE) {
			this._resultContainer.addEventListener('touchstart', () => {
				this._inputElement.blur();
			});
		}
	}

	private inputChangeHandler = debounce(400, event => {
		const yearActive = this._yearSelector.value && this._yearSelector.value !== 'all';
		const countryActive = this._countrySelector.value && this._countrySelector.value !== 'all';
		if (this._inputElement.value !== '' || yearActive || countryActive) {
			TweenMax.to(this._hideItem, 0.4, { height: 0 });
			TweenMax.to(this._resultContainer, 0, { height: 'auto' });
			let filters = {
				filters: {
					page: {
						type: 'press_release'
					}
				}
			};

			if (yearActive) {
				let from = new Date();
				from.setFullYear(parseInt(this._yearSelector.value));
				from.setMonth(0);
				from.setDate(1);
				from.setHours(0, 0, 0, 0);
				let to = new Date(from.getFullYear(), 12, 0);

				(filters.filters.page as any).date = {
					type: 'range',
					from: from.toDateString(),
					to: to.toDateString()
				};
			}

			if (countryActive) {
				(filters.filters.page as any).location_ids = [this._countrySelector.value];
			}

			this.swiftypeAPI.query(this._inputElement.value, this.searchResponseHandler, filters);
		} else {
			TweenMax.to(this._resultContainer, 0, { height: 0 });
			TweenMax.to(this._hideItem, 0.4, { height: 'auto' });
		}
	});

	private searchResponseHandler = (response: SwiftypeAPIResponse) => {
		const records = response.records.page;

		this._currentSpellingSuggestion = '';
		let filterSearchStringOptions = [];

		if (response.config.filters.page.location_ids && response.config.filters.page.location_ids.length > 0) {
			for (let i = 0; i < this._countrySelector.options.length; i++) {
				const option = this._countrySelector.options[i];
				if (option.value === response.config.filters.page.location_ids[0]) {
					filterSearchStringOptions.push(option.text);
				}
			}
		}
		if (response.config.filters.page.date) {
			filterSearchStringOptions.push(new Date(response.config.filters.page.date.from).getFullYear());
		}
		let filterSearchString = response.info.page.query + (filterSearchStringOptions.length > 0 ? (response.info.page.query.length > 0 ? ', ' : '') + filterSearchStringOptions.join(', ') : '');

		if (records.length > 0) {
			this.hasResults = true;
			let responseHTML = records.map(page => {
				return `<a href="${page.nothing}" class="press-item">
					<p class="date rounded-20-light">${new Date(page.field_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
					<p class="press-release-name source-20-regular">${page.title}</p>
				</a>`;
			});
			this._resultContainerList.innerHTML = `<span class="PressSearch__header">${
				response.info.page.total_result_count
			} search results for <span>"${filterSearchString}"</span></span><div class="PressSearch__results-wrapper__results-list">${responseHTML.join('')}</div>`;
		} else {
			this.hasResults = false;
			if (response.info.page.spelling_suggestion) {
				this._resultContainerList.innerHTML = '';
				this._currentSpellingSuggestion = response.info.page.spelling_suggestion.text;
				this._spellingSuggestion.innerHTML = `No results found for <span>${filterSearchString}</span>. Did you mean <span class="Search__search-suggestion__word">${
					this._currentSpellingSuggestion
				}</span>?`;
				this._resultContainerList.appendChild(this._spellingSuggestion);
			} else {
				this._resultContainerList.innerHTML = `<span class="PressSearch__header Search__no-results">No results found for <span>${filterSearchString}</span></span>`;
			}
		}
	};

	private setSpellingSuggestion = event => {
		if (event) {
			event.preventDefault();
		}
		this._inputElement.value = this._currentSpellingSuggestion;
		this._yearSelector.selectedIndex = 0;
		this._countrySelector.selectedIndex = 0;
		this.inputChangeHandler(null);
	};
}
