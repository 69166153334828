import 'lazysizes';
import { WindowManager } from './utils/WindowManager';
import { ScrollController } from './scroll/ScrollController';
import { Globals } from './utils/Globals';
//For further info on gsap imports in es6, see: https://greensock.com/docs/NPMUsage
import { ThrowPropsPlugin } from '../lib/com/greensock/gsap-bonus/ThrowPropsPlugin';
import { DrawSVGPlugin } from '../lib/com/greensock/gsap-bonus/DrawSVGPlugin';

import * as ScrollToPlugin from 'gsap/ScrollToPlugin';
import { TemplateManager } from '../lib/com/hellomonday/templateManager/TemplateManager';
import { ModuleFactory } from '../lib/com/hellomonday/templateManager/ModuleFactory';
import { LinkParser } from '../lib/com/hellomonday/utils/LinkParser';

import { Menu } from './menu/Menu';
//import { DebugGrid } from './debug/DebugGrid';

import { HeroModule } from './modules/hero/HeroModule';
import { DonateModule } from './modules/DonateModule';
import { NewsletterSignupModule } from './modules/NewsletterSignupModule';
import { ImageCarouselModule } from './modules/ImageCarouselModule';
import { NumberStatsModule } from './modules/NumberStatsModule';
import { IconGridModule } from './modules/IconGridModule';
import { TimelineModule } from './modules/TimelineModule';
import { SmallImageCarouselModule } from './modules/SmallImageCarouselModule';
import { FooterBannerModule } from './footer/FooterBannerModule';
import { MapModule } from './modules/MapModule';
import { SingleMediaBreakerModule } from './modules/SingleMediaBreakerModule';
import { CareerOpportunitiesListModule } from './modules/CareerOpportunitiesListModule';
import { JobListingModule } from './modules/JobListingModule';
import { CircularProgressBarModule } from './modules/CircularProgressBarModule';
import { PercentageBarModule } from './modules/PercentageBarModule';
import { DonationFormTemplate } from './templates/DonationFormTemplate';
import { AbstractTemplate } from './templates/AbstractTemplate';
import { TabbedStoriesModule } from './modules/TabbedStoriesModule';
import { PressReleasesOverviewTemplate } from './templates/PressReleasesOverviewTemplate';
import { BigDonateModule } from './modules/BigDonateModule';
import { AlertMessage } from './alertmessage/AlertMessage';
import { PopupModule } from './modules/PopupModule';
import { NewsletterFullSignupFormModule } from './modules/NewsletterFullSignupFormModule';
import { DonationThankYouTemplate } from './templates/DonationThankYouTemplate';
import { LearnMoreBreakerSliderModule } from './modules/LearnMoreBreakerSliderModule';

const plugins = [ScrollToPlugin, ThrowPropsPlugin, DrawSVGPlugin];

const modules = {
	TimelineModule,
	HeroModule,
	NewsletterSignupModule,
	DonateModule,
	ImageCarouselModule,
	NumberStatsModule,
	IconGridModule,
	SmallImageCarouselModule,
	MapModule,
	FooterBannerModule,
	SingleMediaBreakerModule,
	CareerOpportunitiesListModule,
  JobListingModule,
	CircularProgressBarModule,
	PercentageBarModule,
	TabbedStoriesModule,
	BigDonateModule,
	PopupModule,
	NewsletterFullSignupFormModule,
	LearnMoreBreakerSliderModule
};

class Main {
	//Make sure we have one call to WindowManager to init it:
	private windowManager = WindowManager.getInstance();
	private scrollController = new ScrollController();
	public templateManager = new TemplateManager();
	private linkParser = LinkParser.getInstance(this.templateManager);
	private split: any;

	constructor() {
		//Register all modules:
		Globals.IS_IE = this.isIE();
		ModuleFactory.registerModules(modules);

		console.log('Init public/js/src/Main.ts');

		this.setupMainLayers();
	}

	private isIE() {
		var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
		var msie = ua.indexOf('MSIE '); // IE 10 or older
		var trident = ua.indexOf('Trident/'); //IE 11

		return msie > 0 || trident > 0;
	}

	private setupMainLayers() {
		this.initSite();

		let menuDom = document.querySelector('.menu') as HTMLElement;
		if (menuDom) {
			Globals.MENU = new Menu(menuDom, this.templateManager);
		}

		// if (Globals.DEBUG) {
		// 	new DebugGrid();
		// }
		let alertMessage = document.getElementById('AlertMessage');
		if (alertMessage) {
			new AlertMessage(alertMessage as HTMLDivElement);
		}
	}

	private initSite() {
		// Add Templates

		this.templateManager.addTemplate('HomeTemplate', AbstractTemplate);
		this.templateManager.addTemplate('CustomTemplate', AbstractTemplate);
		this.templateManager.addTemplate('FinancialsTemplate', AbstractTemplate);
		this.templateManager.addTemplate('OurLeadershipTemplate', AbstractTemplate);
		this.templateManager.addTemplate('ArticleSubpageTemplate', AbstractTemplate);
		this.templateManager.addTemplate('PersonProfileTemplate', AbstractTemplate);
		this.templateManager.addTemplate('PressReleasesOverviewTemplate', PressReleasesOverviewTemplate);
		this.templateManager.addTemplate('PressReleaseTemplate', AbstractTemplate);
		this.templateManager.addTemplate('DonationFrontpageTemplate', AbstractTemplate);
		this.templateManager.addTemplate('WhereWeWorkTemplate', AbstractTemplate);
		this.templateManager.addTemplate('OptionalHeroTemplate', AbstractTemplate);
		this.templateManager.addTemplate('DonationFormTemplate', DonationFormTemplate);
		this.templateManager.addTemplate('PresskitOverviewTemplate', AbstractTemplate);
		this.templateManager.addTemplate('PresskitSeasonOverviewTemplate', AbstractTemplate);
		this.templateManager.addTemplate('DonationThankYouTemplate', DonationThankYouTemplate);
		this.templateManager.init(Globals.TEMPLATE_LAYER, 'home');

		WindowManager.signalResize.add(this.resize, this);
		this.resize();
	}

	public resize() {
		this.templateManager.resize();
		if (Globals.IS_TOUCH_DEVICE) {
			document.documentElement.classList.add('touch');
		}
	}
}

declare global {
	interface Window {
		Main: Main;
	}
}

window.onload = () => {
	if (!document.body.querySelector('.ErrorTemplate')) {
		window.Main = new Main();
	}
};
