import { Signal } from '../../lib/com/hellomonday/signals/Signal';
import { TweenMax, Power1, Tween } from 'gsap/TweenMax';
import { MenuSizeController } from './MenuSizeController';

export abstract class MenuSection {
	public hoverSignal: Signal = new Signal();
	public expandSignal: Signal = new Signal();
	public toggleSignal: Signal = new Signal();
	public signalKeyboardContract: Signal = new Signal();

	protected topLink: HTMLElement;
	private _expanded: boolean = true;
	private _menuSizeController: MenuSizeController;

	protected mobileContainer: HTMLElement;
	private _desktopContainer: HTMLElement;

	public _submenu: HTMLElement;
	private _submenuItems: HTMLElement[];
	private _submenuElementsCount: number;

	private _topLinkATag: HTMLElement;

	protected animationTime: number = 0.4;

	private _tweens: Tween[] = [];

	public constructor(topLink: HTMLElement, submenu: HTMLElement, desktopContainer: HTMLElement, mobileContainer: HTMLElement, menuSizeController: MenuSizeController) {
		this.topLink = topLink;
		this._submenu = submenu;
		this.mobileContainer = mobileContainer;
		this._desktopContainer = desktopContainer;

		this._menuSizeController = menuSizeController;

		this._menuSizeController.onContracted.add(this.menuContracted, this);
		//console.log(this.getSubMenu());
		if (this.getSubMenu() !== null) {
			this._submenuItems = [].slice.call(this.getSubMenu().querySelectorAll('li'));
			this._submenuElementsCount = this._submenuItems.length;
		}

		this._topLinkATag = this.topLink.querySelector('a');

		this.prepare();

		this.contract();
		this.hideItems();
	}

	private menuContracted() {
		this.contract();
	}

	public getContainer() {
		return this.mobileContainer;
	}

	protected abstract prepare();

	public setMobileMode() {
		this.topLink.removeEventListener('mouseenter', this.hover);

		this.topLink.removeEventListener('keydown', this.keydownHandler);
		if (this._submenu !== null) {
			this._submenu.removeEventListener('keydown', this.keydownHandler);
			this.mobileContainer.appendChild(this._submenu);
		}
	}

	public setDesktopMode() {
		if (this.topLink !== undefined) {
			this.topLink.addEventListener('mouseenter', this.hover);
			this.topLink.addEventListener('keydown', this.keydownHandler);
			if (this._submenu !== null) {
				this._submenu.addEventListener('keydown', this.keydownHandler);

				this._desktopContainer.appendChild(this._submenu);
			}
		}
	}

	private keydownHandler = (event: KeyboardEvent) => {
		if (event.keyCode === 40) {
			//Down arrow;
			event.preventDefault();
			this.hover(event, true);
			return false;
		} else if (event.keyCode === 38) {
			this.contract(true);
			event.preventDefault();
			return false;
		}
	};

	protected click = (e: MouseEvent) => {
		this.toggle();
	};

	private hover = (e: MouseEvent | KeyboardEvent, focusFirstChild = false) => {
		this.expand(focusFirstChild);

		this.hoverSignal.dispatch(this);
	};

	private toggle() {
		if (this._expanded) {
			this.contract();
		} else {
			this.expand();
		}
	}

	public expand(focusFirstChild = false) {
		if (this._expanded) return;

		this.killTweens();
		this.hideItems();

		this._topLinkATag.setAttribute('aria-expanded', 'true');
		this._topLinkATag.classList.add('hovered');
		this.transitionToExpandedState();
		this.doExpandAnimation();
		if (focusFirstChild) {
			if (this._submenuItems && this._submenuItems.length > 0) {
				(this._submenu.querySelector('li a') as HTMLAnchorElement).focus();
				document.querySelector('header .menu-wrapper').scrollTop = 0;
			}
		}

		this._expanded = true;

		this.expandSignal.dispatch(this);
		this.toggleSignal.dispatch(this);
	}

	protected transitionToExpandedState() {
		if (this._submenu !== null) {
			this._submenu.classList.remove('hide');
		}
	}

	public contract(fromKeyboard = false) {
		if (!this._expanded) return;

		this._topLinkATag.setAttribute('aria-expanded', 'false');
		this._topLinkATag.classList.remove('hovered');
		if (fromKeyboard) {
			this._topLinkATag.focus();
			this.signalKeyboardContract.dispatch();
		}
		this.transitionToContractedState();
		this.doContractAnimation();

		this._expanded = false;
		this.toggleSignal.dispatch(this);
	}

	protected transitionToContractedState() {
		// console.log(this._submenu);
		if (this._submenu !== null) {
			this._submenu.classList.add('hide');
		}
	}

	protected doContractAnimation() {
		this.killTweens();

		TweenMax.delayedCall(this.animationTime, this.hideItems, null, this);
		TweenMax.to(this.mobileContainer, this.animationTime, { className: '+=contracted', ease: Power1.easeInOut });
	}

	private hideItems() {
		//console.log(this._submenuItems);
		if (this._submenuItems !== undefined) {
			this._submenuItems.forEach(item => {
				TweenMax.set(item, { y: -5, opacity: 0 });
			});
		}
	}

	protected doExpandAnimation() {
		this.killTweens();

		TweenMax.to(this.mobileContainer, this.animationTime, { className: '-=contracted', ease: Power1.easeInOut });
		this._tweens = TweenMax.staggerTo(this._submenuItems, 0.2, { y: 0, opacity: 1, delay: this.animationTime * 0.2, ease: Power1.easeInOut }, this.animationTime / this._submenuElementsCount);
	}

	private killTweens() {
		TweenMax.killDelayedCallsTo(this.hideItems);

		this._tweens.forEach(tween => {
			tween.progress(1).kill();
		});
	}

	public getSubmenuHeight(): number {
		let submenu = this._submenu;
		if (submenu !== null) {
			submenu.style.display = 'flex';
			const height = submenu.clientHeight;
			submenu.style.display = '';

			return height;
		}
	}

	protected getSubMenu(): HTMLElement {
		return this._submenu;
	}
}
