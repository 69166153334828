import { AbstractTemplate } from '../templates/AbstractTemplate';
import { Carousel } from './carousel/Carousel';
import { WindowManager } from '../utils/WindowManager';
import { NextPrevButtons } from './timeline/NextPrevButtons';
import { Globals } from '../utils/Globals';

export class SmallImageCarouselModule extends Carousel {
	private _carouselElements: NodeListOf<HTMLElement>;
	private _nextPrevButtons: NextPrevButtons;

	get carouselElements() {
		return this._carouselElements;
	}

	protected prepare() {
		this.prepareImages();
		this._nextPrevButtons = new NextPrevButtons(this.element, this);

		super.prepare();
	}

	private prepareImages() {
		this._carouselElements = this.element.querySelectorAll('.ImageGridItem');
	}

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element.querySelector('.dragged-area'), element.querySelector('.carousel-container'), element, template);
	}

	protected getSnapPoints(): number[] {
		let snapPoints = [];

		let max = this.getWidth() - this.getScrollAreaWidth();

		let maxExceeded = false;

		if (Globals.RTL) {
			snapPoints.push(0);
		}
		this.carouselElements.forEach(element => {
			if ((!Globals.RTL && element.offsetLeft <= max) || (Globals.RTL && element.offsetLeft <= 0)) {
				snapPoints.push(-element.offsetLeft);
			} else {
				maxExceeded = true;
			}
		});

		if (maxExceeded) {
			snapPoints.push(-this.getWidth() + this.getScrollAreaWidth());
		}

		return snapPoints;
	}

	protected getScrollAreaWidth(): number {
		let width = this.carouselContainer.offsetWidth;

		if (WindowManager.width > WindowManager.BREAKPOINT_TABLET) {
			width -= Math.round((this.carouselContainer.clientWidth + 20) * (2 / 12));
		} else if (WindowManager.width > WindowManager.BREAKPOINT_TABLET_PORTRAIT) {
			width -= Math.round((this.carouselContainer.clientWidth + 20) * (1 / 12));
		}

		return width;
	}

	protected getWidth(): number {
		let lastItem = this._carouselElements[this._carouselElements.length - 1];
		if (Globals.RTL) {
			return lastItem.offsetLeft - lastItem.offsetWidth;
		}
		return lastItem.offsetLeft + lastItem.offsetWidth;
	}

	public kill() {
		this._nextPrevButtons.kill();

		super.kill();
	}
}
