import { Button } from '../../buttons/Button';
import { TweenMax } from 'gsap/TweenMax';

export class StoryTab extends Button {
	private _isActive: boolean = false;
	private _tabContent: HTMLElement;

	private _id: number;

	constructor(element: HTMLElement, tabContent: HTMLElement, id: number) {
		super(element);

		this._tabContent = tabContent;
		this._id = id;

		// this._tabContent.style.position = 'absolute';
		// this._tabContent.style.display = 'block';

		if (element.classList.contains('active')) {
			this._isActive = true;
		}
	}

	protected shouldClick(): boolean {
		if (!super.shouldClick()) return false;

		return !this._isActive;
	}

	public activate() {
		if (this._isActive) return;

		this.element.classList.add('active');
		this._tabContent.style.display = 'block';

		TweenMax.set(this._tabContent, { y: -20, opacity: 0 });
		TweenMax.to(this._tabContent, 0.2, { y: 0, delay: 0.2, opacity: 1 });

		this._isActive = true;
	}

	public deactivate() {
		if (!this._isActive) return;
		this.element.classList.remove('active');

		TweenMax.to(this._tabContent, 0.2, { y: -20, opacity: 0, onComplete: this.hide, onCompleteScope: this });

		this._isActive = false;
	}

	private hide() {
		this._tabContent.style.display = 'none';
	}

	public getId(): number {
		return this._id;
	}

	public getContentHeight(): number {
		let currentDisplay = this._tabContent.style.display;
		this._tabContent.style.display = 'block';

		const height = this._tabContent.offsetHeight;

		this._tabContent.style.display = currentDisplay;

		return height;
	}
}
