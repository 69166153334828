import { AbstractTemplate } from '../templates/AbstractTemplate';
import { TweenMax, Power1 } from 'gsap/TweenMax';
import { IntersectionObserverModule } from '../general/IntersectionObserverModule';

export class PercentageBarModule extends IntersectionObserverModule {
	private _bars: NodeListOf<HTMLElement>;
	private _texts: NodeListOf<HTMLElement>;

	private _animationTime: number = 0.5;

	private _barWidths: string[] = [];

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		this._bars = element.querySelectorAll('.percentage-bar>.bar');
		this._texts = element.querySelectorAll('.percentage-data>.data-text');

		this.observe(element);

		let i = 0;

		this._bars.forEach(element => {
			this._barWidths[i] = element.style.width;

			element.classList.remove('hidden');
			TweenMax.set(element, { width: '0%' });

			i++;
		});
	}

	protected elementIntersectFound(element: Element) {
		console.log('found');
		this.show();
	}

	protected getOptions(): IntersectionObserverInit {
		return { threshold: [0.8] };
	}

	private show() {
		let i = 0;
		let delayTime: number = 0;

		this._bars.forEach(element => {
			const animationTime = this._animationTime;
			let ease = Power1.easeInOut;

			TweenMax.to(element, animationTime, { width: this._barWidths[i], delay: delayTime, ease: ease });
			TweenMax.to(this._texts[i], 0.3, { className: '-=hidden', delay: delayTime });

			i++;
			delayTime += animationTime + 0.1;
		});
	}
}
