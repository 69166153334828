import { TimelineElement } from './TimelineElement';

export class YearTimelineElement extends TimelineElement {
	private _year: number;

	constructor(element: HTMLElement) {
		super(element);

		this._year = parseInt(element.querySelector('h2').innerText);
	}

	public resize(newWidth: number) {
		if (newWidth == -1) {
			this.element.style.width = '';
		} else {
			this.element.style.width = newWidth + 'px';
		}
	}

	public getYear(): number {
		return this._year;
	}
}
