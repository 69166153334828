import { AbstractModule } from '../../lib/com/hellomonday/templateManager/AbstractModule';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { IconGridItem } from './iconGrid/IconGridItem';

export class IconGridModule extends AbstractModule {
	private _gridItems: IconGridItem[] = [];

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element, template);

		let items = element.querySelectorAll('.grid>div');

		items.forEach(item => {
			this._gridItems.push(new IconGridItem(item as HTMLElement));
		});
	}

	public resize() {
		super.resize();

		this._gridItems.forEach(gridItem => {
			gridItem.resize();
		});
	}
}
