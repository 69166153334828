import { YearTimelineElement } from './YearTimelineElement';
import { Dot } from './Dot';
import { TimelineElement } from './TimelineElement';
import { TimelineModule } from '../TimelineModule';

export class YearDot extends Dot {
	constructor(timelineElement: YearTimelineElement, timeline: TimelineModule) {
		super(timelineElement, timeline);

		this.getElement().addEventListener('click', this.click);
	}

	private click = () => {
		this.timeline.tweenToElement(this.timelineElement);
	};

	protected createDot(timelineElement: TimelineElement): HTMLElement {
		let element = document.createElement('div');
		element.classList.add('dot-container');
		element.innerHTML =
			'<div class="dot-hitarea"><div class="hover-dot"></div><div class="dot"></div><p class="year rounded-16-light">' + (timelineElement as YearTimelineElement).getYear() + '</p></div>';

		return element;
	}
}
