import { SwiftypeAPI, SwiftypeAPIResponse } from './SwiftypeAPI';
import { TweenMax } from 'gsap/TweenMax';
import { debounce } from 'throttle-debounce';
import { WindowManager } from '../utils/WindowManager';
import { getScrollBarWidth } from '../../lib/com/hellomonday/utils/ScrollbarWidth';
import { Menu } from '../menu/Menu';
import { Globals } from '../utils/Globals';
import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class Search {
	private _element: HTMLElement;
	private _searchIcon: HTMLElement;

	private swiftypeAPI: SwiftypeAPI;

	private _inputElement: HTMLInputElement;

	private _visible = false;
	private _closeIcon: Element;
	private _searchResultsWrapper: Element = document.createElement('div');
	private _searchResultsWrapperInner: Element = document.createElement('div');
	private _spellingSuggestion: Element = document.createElement('a');
	private _backgroundElement = document.createElement('div');

	private hasResults = false;
	private _currentSpellingSuggestion: string = '';
	private _scrollBarWidth: number;
	private _menu: Menu;

	public onToggle: Signal = new Signal();

	constructor(element: HTMLElement, searchIcon: HTMLElement, menu: Menu) {
		this._element = element;
		this._searchIcon = searchIcon;
		this._menu = menu;
		this._inputElement = this._element.querySelector('input[type="search"]');
		this._closeIcon = this._element.querySelector('.Search__close-icon');
		this._searchResultsWrapper.classList.add('Search__results-wrapper');
		this._searchResultsWrapper.classList.add('container');
		this._searchResultsWrapper.classList.add('padding');
		this._searchResultsWrapperInner.classList.add('default-content-inset');
		this._searchResultsWrapper.appendChild(this._searchResultsWrapperInner);
		this._spellingSuggestion.classList.add('Search__header');
		this._spellingSuggestion.classList.add('Search__search-suggestion');

		this._backgroundElement.classList.add('Search__background');

		document.body.appendChild(this._backgroundElement);
		document.body.appendChild(this._searchResultsWrapper);

		this.swiftypeAPI = new SwiftypeAPI('Ges1QrnUhzayM1CSEyxT');

		this._inputElement.addEventListener('input', this.inputChangeHandler);

		this._searchIcon.addEventListener('click', this.toggleVisible);
		this._closeIcon.addEventListener('click', this.close);
		this._spellingSuggestion.addEventListener('click', this.setSpellingSuggestion);
		this._backgroundElement.addEventListener('click', this.close);

		if (Globals.IS_TOUCH_DEVICE) {
			this._searchResultsWrapper.addEventListener('touchstart', () => {
				this._inputElement.blur();
			});
		}

		WindowManager.signalResize.add(this.resizeHandler);
		this.resizeHandler();
	}

	private inputChangeHandler = debounce(400, event => {
		if (this._inputElement.value !== '') {
			this.swiftypeAPI.query(this._inputElement.value, this.searchResponseHandler);
		}
	});

	private searchResponseHandler = (response: SwiftypeAPIResponse) => {
		const records = response.records.page;

		this._currentSpellingSuggestion = '';
		if (records.length > 0) {
			this.hasResults = true;
			let responseHTML = records.map(page => {
				//let highlightItems = Object.values(page.highlight);
				let highlight = page.search_api_excerpt;
        if (!highlight.length) {
          highlight = page.rendered_item;
        }
        // Replace all kind of new lines.
        highlight = highlight.replace(/(\r\n|\n|\r)/gm,"");
        highlight = highlight.replace(/[\r\n]+/g, '');
        // Replace irrelevant spaces between lines.
        highlight = highlight.replace(/\s+/g, ' ');
        // Remove first and last space.
        highlight = highlight.trim();
        // Replace beginning and end double quotes.
        highlight = highlight.replace(/(^")|("$)/g, '');
        // Replace &amp;nbsp; characters.
        highlight = highlight.replace(/&amp;nbsp;/g, ' ');

				/*if (highlightItems.length > 0) {
					if (page.search_api_excerpt) {
						highlight = page.search_api_excerpt;
					} else {
						highlight = highlightItems[0] as string;
					}
				} else {
				}*/
				let imgSrc = '/assets/svg/embedded/search_fallback_logo.svg';
				let imgClass = 'fallback';
				if (page.field_image_url && page.field_image_url !== '') {
					imgSrc = page.field_image_url;
					imgClass = '';
				}
				return `<a href="${page.nothing}" class="Search__reponse-item">
							<div class="Search__response-item__image-wrapper">
								<img data-src="${imgSrc}" class="lazyload ${imgClass}" />
							</div>
							<div class="Search__response-item__description-wrapper">
								<h4 class="source-14-regular">${page.title} | Sesame Workshop India</h4>
								<div class="source-16-regular Search__response-item__highlight">${highlight}</div>
							</div>
						</a>`;
			});
			this._searchResultsWrapperInner.innerHTML = `<span class="Search__header">${response.info.page.total_result_count} search results for <span>${
				response.info.page.query
			}</span></span><div class="Search__results-wrapper__results-list">${responseHTML.join('')}</div>`;
		} else {
			this.hasResults = false;
			if (response.info.page.spelling_suggestion) {
				this._searchResultsWrapperInner.innerHTML = '';
				this._currentSpellingSuggestion = response.info.page.spelling_suggestion.text;
				this._spellingSuggestion.innerHTML = `No results found for <span>${response.info.page.query}</span>. Did you mean <span class="Search__search-suggestion__word">${
					this._currentSpellingSuggestion
				}</span>?`;
				this._searchResultsWrapperInner.appendChild(this._spellingSuggestion);
			} else {
				this._searchResultsWrapperInner.innerHTML = `<span class="Search__header Search__no-results">No results found for <span>${response.info.page.query}</span></span>`;
			}
		}
		if (this._visible) {
			if (!Globals.IS_TOUCH_DEVICE) {
				TweenMax.to(this._searchResultsWrapper, 0.5, { scrollTo: 0 });
			}
			this.disableScroll();
		}
	};

	private toggleVisible = event => {
		if (event) {
			event.preventDefault();
		}
		TweenMax.to(this._element, 0.2, { autoAlpha: this._visible ? 0 : 1 });
		TweenMax.to(this._backgroundElement, 0.2, { autoAlpha: this._visible ? 0 : 1 });

		this._visible = !this._visible;
		if (this._visible) {
			if (this.hasResults) {
				this.disableScroll();
			}

			document.documentElement.classList.add('Search__visible');
			if (this._menu.isExpanded()) {
				this._menu.contract();
			}
			this._menu.keepVisible();
			document.documentElement.addEventListener('keyup', this.keyUpHandler);
			TweenMax.delayedCall(0.05, () => {
				this._inputElement.focus();
			});
		} else {
			document.documentElement.classList.remove('Search__visible');
			document.documentElement.removeEventListener('keyup', this.keyUpHandler);
			this._menu.hideOnScroll(false);
			this.enableScroll();
		}

		this.onToggle.dispatch(this._visible);
	};

	private disableScroll() {
		// this._menu.setSearchVisible();
		TweenMax.set(this._searchResultsWrapper, { overflow: '', display: 'flex' });
		if (!Globals.IS_TOUCH_DEVICE) {
			TweenMax.set(document.body, { overflow: 'hidden' });
			TweenMax.set(Globals.TEMPLATE_LAYER, { width: `calc(100% - ${this._scrollBarWidth}px)` });
			TweenMax.set('header.menu', { width: `calc(100% - ${this._scrollBarWidth}px)` });
		}
		TweenMax.to(this._searchResultsWrapper, 0.2, { autoAlpha: 1 });

		if (Globals.IS_TOUCH_DEVICE) {
			TweenMax.to(window, 0.3, { scrollTo: { y: 0, autoKill: false } });
		}
	}

	private enableScroll() {
		if (!Globals.IS_TOUCH_DEVICE) {
			TweenMax.set(this._searchResultsWrapper, { overflow: 'hidden' });
			TweenMax.set(document.body, { overflow: '' });
			TweenMax.set(Globals.TEMPLATE_LAYER, { width: '' });
			TweenMax.set('header.menu', { width: '' });
		}
		TweenMax.to(this._searchResultsWrapper, 0.2, { autoAlpha: 0, display: 'none' });
	}

	private close = event => {
		if (this._visible) {
			this.toggleVisible(event);
		}
	};

	private keyUpHandler = event => {
		if (event.key === 'Escape') {
			this.close(event);
		}
	};

	private setSpellingSuggestion = event => {
		if (event) {
			event.preventDefault();
		}
		this._inputElement.value = this._currentSpellingSuggestion;
		this.inputChangeHandler(null);
	};

	private resizeHandler = () => {
		this._scrollBarWidth = getScrollBarWidth();
		// TweenMax.set(this._searchResultsWrapper, { minHeight: Globals.TEMPLATE_LAYER.clientHeight });
	};
}
