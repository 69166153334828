import { Dot } from './Dot';

export class SegmentDot extends Dot {
	protected createDot(): HTMLElement {
		let element = document.createElement('div');

		element.classList.add('dot-container');
		element.innerHTML = '<div class="segment-dot"></div>';

		return element;
	}
}
