import { Carousel } from './carousel/Carousel';
import { AbstractTemplate } from '../templates/AbstractTemplate';
import { CarouselDots } from './carousel/CarouselDots';
import { CarouselItem } from './carousel/CarouselItem';
import { NextPrevButtons } from './timeline/NextPrevButtons';
import { Globals } from '../utils/Globals';

export class ImageCarouselModule extends Carousel {
	private _carouselItems: CarouselItem[];
	private _dots: CarouselDots;
	private _nextPrevButtons: NextPrevButtons;

	private _currentCarouselItem: CarouselItem;

	constructor(element: HTMLElement, template: AbstractTemplate) {
		super(element.querySelector('.dragged-area'), element.querySelector('.carousel-container'), element, template);
	}

	protected prepare() {
		this._dots = new CarouselDots(this.element.querySelector('.dots-container'));
		this.prepareImages();
		this._nextPrevButtons = new NextPrevButtons(this.element, this);

		super.prepare();

		this.onSnapChanged.add(this.snapChanged, this);
	}

	protected getWidth(): number {
		let lastItem = this._carouselItems[this._carouselItems.length - 1];

		if (Globals.RTL) {
			return lastItem.element.offsetLeft - lastItem.element.offsetWidth;
		}
		return lastItem.element.offsetLeft + lastItem.element.offsetWidth;
	}

	protected dragged() {
		super.dragged();

		const currentCarouselIndex = this.getCurrentSnapPointIndex();

		this._carouselItems[currentCarouselIndex].scaleItem(this.getCurrentScrollX());

		if (currentCarouselIndex != 0) {
			this._carouselItems[currentCarouselIndex - 1].scaleItem(this.getCurrentScrollX());
		}

		if (currentCarouselIndex < this._carouselItems.length - 1) {
			this._carouselItems[currentCarouselIndex + 1].scaleItem(this.getCurrentScrollX());
		}
	}

	private prepareImages() {
		const carouselItems: NodeListOf<HTMLElement> = this.element.querySelectorAll('.carousel-item');
		const itemCount: number = carouselItems.length;

		this._carouselItems = [];

		for (let i = 0; i < itemCount; i++) {
			const item: CarouselItem = new CarouselItem(carouselItems[i], i);

			this._carouselItems.push(item);
			this._dots.createDot(item);

			item.onActivate.add(this.itemActivated, this);
			item.onScrollToItem.add(this.scrollToItem, this);
		}

		this.updateSnapPoints();
		this._carouselItems[0].fireScrollToItem();
	}

	private scrollToItem(item: CarouselItem) {
		this.tweenToSnapIndex(item.snapIndex);
	}

	private snapChanged(newIndex: number) {
		this._carouselItems.forEach(element => {
			if (element.snapIndex == newIndex) {
				element.activate();
			}
		});
	}

	private itemActivated(item: CarouselItem) {
		if (this._currentCarouselItem != null) this._currentCarouselItem.deactivate();
		item.activate();

		this._currentCarouselItem = item;
	}

	protected getSnapPoints(): number[] {
		let snapPoints = [];

		for (let i = 0; i < this._carouselItems.length; i++) {
			this._carouselItems[i].snapIndex = i;
			snapPoints.push(-this._carouselItems[i].element.offsetLeft);
		}

		return snapPoints;
	}

	public kill() {
		this._carouselItems.forEach(item => {
			item.onActivate.remove(this.itemActivated, this);
			item.onScrollToItem.remove(this.scrollToItem, this);
			item.kill();
		});

		this._nextPrevButtons.kill();

		super.kill();
	}
}
