import { TweenMax } from 'gsap/TweenMax';
import { ScrollController } from '../scroll/ScrollController';
import { Menu } from './Menu';

export class MenuScrollVisibilityHandler {
	private _menu: Menu;

	private _menuWrapper: HTMLElement;
	private _shadowVisible: boolean = false;
	private _menuVisible: boolean = true;

	private lastScroll: number = 0;

	public _isActive: boolean = false;

	constructor(menuWrapper: HTMLElement, menu: Menu) {
		this._menuWrapper = menuWrapper;
		this._menu = menu;

		this.activate();
	}

	public hideShadow() {
		if (!this._shadowVisible) return;

		TweenMax.to(this._menuWrapper, 0.2, { className: '-=shadow', overwrite: 0 });
		this._shadowVisible = false;
	}

	public showShadow() {
		if (this._shadowVisible) return;

		TweenMax.to(this._menuWrapper, 0.2, { className: '+=shadow', overwrite: 0 });
		this._shadowVisible = true;
	}

	public showMenu() {
		if (this._menuVisible) return;

		TweenMax.to(this._menuWrapper, 0.2, { className: '-=hidden', overwrite: 0 });
		this._menuVisible = true;
	}

	public hideMenu() {
		if (!this._menuVisible) return;

		TweenMax.to(this._menuWrapper, 0.2, { className: '+=hidden', overwrite: 0 });
		this.hideShadow();

		this._menuVisible = false;
	}

	private scrolled() {
		let scrollDelta = ScrollController.viewport.top - this.lastScroll;
		this.lastScroll = ScrollController.viewport.top;

		if (!this._menu.isExpanded()) {
			if (scrollDelta >= 0 && ScrollController.viewport.top > 100) {
				this.hideMenu();
			} else {
				this.showMenu();
			}
		}

		if (ScrollController.viewport.top > 20 && this._menuVisible) {
			this.showShadow();
		} else {
			this.hideShadow();
		}
	}

	public deactivate() {
		if (!this._isActive) return;

		ScrollController.signalScroll.remove(this.scrolled, this);

		this.showMenu();
		this.showShadow();

		this._isActive = false;
	}

	public activate(checkScrollPosition = true) {
		if (this._isActive) return;

		ScrollController.signalScroll.add(this.scrolled, this);

		if (checkScrollPosition) {
			this.scrolled();
		}
		this._isActive = true;
	}

	public toggle() {
		if (this._isActive) {
			this.deactivate();
		} else {
			this.activate();
		}
	}
}
