export function setCookie(key: string, value: string | number, expiresInDays: number) {
	let d = new Date();
	d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000);
	const expiresString = 'expires=' + d.toUTCString();
	document.cookie = key + '=' + value + ';' + expiresString + ';path=/';

	return 'added cookie: ' + key + '=' + value;
}

export function getCookie(key: string): string | null {
	let name = key + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
}

export function deleteCookie(key: string) {
	document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	return 'deleted cookie with key: ' + key;
}
