import { Signal } from '../../lib/com/hellomonday/signals/Signal';

export class Button {
	public onClick = new Signal();
	public element: HTMLElement;

	private _isListening: boolean = false;

	constructor(element: HTMLElement, startListening: boolean = true) {
		this.element = element;

		if (startListening) {
			this.addClickListener();
		}
	}

	//used to validate clicks in child classes.
	protected shouldClick(): boolean {
		return true;
	}

	protected click = (e: MouseEvent) => {
		e.preventDefault();

		if (!this.shouldClick()) return;

		this.onClick.dispatch(this);
	};

	public addClickListener() {
		if (this._isListening) {
			return;
		}

		this.element.addEventListener('click', this.click);
		this._isListening = true;
	}

	public removeClickListener() {
		this.element.removeEventListener('click', this.click);
		this._isListening = false;
	}
}
