import { Globals } from '../utils/Globals';
import { TemplateManager } from '../../lib/com/hellomonday/templateManager/TemplateManager';
import { Power1, TweenMax } from 'gsap/TweenMax';
import { ModuleFactory } from '../../lib/com/hellomonday/templateManager/ModuleFactory';
import { LinkParser } from '../../lib/com/hellomonday/utils/LinkParser';

export class AbstractTemplate {
	public path:string = null;
	protected element:HTMLElement;
	private modules = [];
	private _templateManager:TemplateManager;

	constructor(element:HTMLElement, templateManager:TemplateManager) {
		this.element = element;
		this._templateManager = templateManager;
	}

	private buildModules() {
		let moduleData = this.element.querySelectorAll('[data-module]');

		LinkParser.getInstance().parseNodeList(this.element.querySelectorAll('a:not(.LinkParser-ignore)') as NodeListOf<HTMLAnchorElement>);

		let i = 0;
		let l = moduleData.length;
		let module;
		let moduleName:string;

		for (i; i < l; i++) {
			moduleName = moduleData[i].getAttribute('data-module');

			if (moduleName) {

				module = ModuleFactory.create(moduleName, moduleData[i], this);

				if (module) {
					this.modules.push(module);
				}
			}
		}
	}

	public templateIn(historyNavigationOccured = false) {

		if (!this._templateManager.isInitial()) {
			TweenMax.set(this.element, { opacity: 0 });
			if (!historyNavigationOccured) {
				window.scrollTo(0, 0);
			}
			Globals.TEMPLATE_LAYER.appendChild(this.element);

			this.buildModules();

			TweenMax.to(this.element, 0.2, {
				opacity: 1,
				ease: Power1.easeOut,
				onComplete: () => this.templateInComplete()
			});
		} else {
			this.buildModules();
			this.templateInComplete();
		}
	};

	public templateInComplete() {

		this._templateManager.nextTemplate();
	};

	public templateOut(newPath, speed) {

		// console.log('templateOut', this);

		TweenMax.to(this.element, 0.2, {
			opacity: 0,
			ease: Power1.easeOut,
			onComplete: () => this.templateOutComplete()
		});
	};

	public templateOutComplete() {

		// console.log('templateOutComplete', this._templateManager.nextTemplate);
		Globals.TEMPLATE_LAYER.removeChild(this.element);

		this.kill();

		// console.log('templateOutComplete', this._templateManager.nextTemplate);

		this._templateManager.nextTemplate();
	};

	public resize() {
		// console.log('Abstract template resize');
		let l = this.modules.length;

		for (let i = 0; i < l; i++) {
			this.modules[i].resize();
		}
	};

	public kill() {
		let l = this.modules.length;

		for (let i = 0; i < l; i++) {
			this.modules[i].kill();
		}

	};

}
