import { Sine, TweenMax } from 'gsap/TweenMax';
import { getCookie, setCookie } from '../../lib/com/hellomonday/utils/CookieUtils';

export class AlertMessage {
	public static COOKIE_KEY = 'AlertMessage';
	private removing = false;
	private element: HTMLDivElement;
	constructor(element: HTMLDivElement) {
		this.element = element;
		let shown = getCookie(AlertMessage.COOKIE_KEY);
		if (shown !== element.dataset.messageId) {
			TweenMax.to(element, 0.3, { y: 0, ease: Sine.easeOut });
		} else {
			this.removeMessage(true);
		}

		element.querySelector('.AlertMessage__close').addEventListener('click', event => {
			event.preventDefault();
			this.removeMessage();
			return false;
		});
	}

	private removeMessage = (instant = false) => {
		if (this.removing) return;
		this.removing = true;
		setCookie(AlertMessage.COOKIE_KEY, this.element.dataset.messageId + '', 1);
		TweenMax.to(this.element, instant ? 0 : 0.3, {
			yPercent: -100,
			ease: Sine.easeOut,
			onComplete: () => {
				this.element.parentNode.removeChild(this.element);
			}
		});
	};
}
